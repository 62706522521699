.FaqsContainer {
  padding: 12vh 0;
}
.FaqsS1 {
  padding: 5vw 10vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5vw;
}
.FaqsS1P1 {
  color: var(--gray, #5b7a99);
  font-family: SanDiego;
  font-size: 1vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
  text-transform: uppercase;
}
.FaqsS1P2 {
  color: var(--systemGrey-900, #212121);
  text-align: center;
  font-family: Coco;
  font-size: 2.5vw;
  font-style: normal;
  font-weight: 500;
  line-height: 123%; /* 59.04px */
  letter-spacing: 1.2px;
  text-transform: uppercase;
}
.FaqsS1P3 {
  color: var(--gray, #5b7a99);
  font-family: SanDiego;
  font-size: 1vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
}
.FaqsS {
  padding-top: 3vw;
  display: flex;
  flex-direction: column;
  gap: 3vw;
}
.FaqsSP1 {
  color: var(--black, #000);
  text-align: center;
  font-family: SanDiego;
  font-size: 2vw;
  font-style: normal;
  font-weight: 500;
  line-height: 126%; /* 40.32px */
  text-transform: uppercase;
}
@media (max-width: 575px) {
  .FaqsContainer {
    padding-bottom: 0;
  }
  .FaqsS1P1 {
    font-size: 3.5vw;
  }
  .FaqsS1P2 {
    font-size: 4vw;
  }
  .FaqsS1P3 {
    font-size: 3.5vw;
    text-align: center;
  }
  .FaqsSP1 {
    padding-top: 3vh;
    font-size: 4.5vw;
  }
}
/*  */
/*  */
/*  */
.FaqsS5Container {
  padding-top: 5vh;
}
.FaqsS5 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 5vw 10vw;
  background-image: url("../../Assets/svg/About/AboutS5BG.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  gap: 2vw;
}
.FaqsS5P1 {
  color: var(--gray, #5b7a99);
  font-family: SanDiego;
  font-size: 1.2vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}
.FaqsS5P2 {
  color: var(--systemGrey-900, #212121);
  font-family: Coco;
  font-size: 2vw;
  font-style: normal;
  font-weight: 500;
  line-height: 123%;
  letter-spacing: 0.2px;
  text-transform: uppercase;
}
.FaqsS5P3 button {
  display: flex;
  align-items: center;
  padding: 1vw 2vw;
  border-radius: 100px;
  background: #19499b;
  border: none;
  color: #f6f8fb;
  font-family: SanDiego;
  font-size: 1vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
  cursor: pointer;
}
.FaqsS5P3 a,
.FaqsS5P3 a:active,
.FaqsS5P3 a:visited {
  text-decoration: none;
}
@media (max-width: 575px) {
  .FaqsS5 {
    background-image: url("../../Assets/svg/About/AboutS5BGMOB.svg");
    background-size: contain;
    gap: 4vw;
    height: 37vh;
  }
  .FaqsS5P1 {
    font-size: 3.5vw;
  }
  .FaqsS5P2 {
    font-size: 4vw;
  }
  .FaqsS5P3 {
    padding-top: 3vh;
  }
  .FaqsS5P3 button {
    padding: 2vw 5vw;
    font-size: 4vw;
  }
}
