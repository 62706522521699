.slider_container {
  width: 80vw;
  border-radius: 8px;
  background: var(--gray, #5b7a99);
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  height: 100%;
}
.Home_Slider_Left {
  padding: 3vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.HomeSliderLP1 {
  overflow: hidden;
  color: var(--white, #fff);
  font-family: SanDiego;
  font-size: 1.1vw;
  font-style: normal;
  font-weight: 300;
  line-height: 124%; /* 24.8px */
  letter-spacing: 0.5px;
  align-self: flex-start;
}
.HomeSliderLP2 {
  display: flex;
  justify-content: space-between;
}

.HomeSliderLP2V1,
.image-placeholder {
  color: var(--white, #fff);
  font-family: SanDiego;
  font-size: 1.1vw;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0.2px;
  text-transform: capitalize;
}
.star_image {
  width: 1.3vw;
  margin-right: 5px;
}
.HomeSliderLP2V2 {
  color: var(--white, #fff);
  font-family: SanDiego;
  font-size: 1vw;
  line-height: 32px; /* 133.333% */
  letter-spacing: 0.2px;
  text-transform: capitalize;
  align-self: flex-end;
}
/*  */

/*  */
.slider_image {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.slider_image img {
  border-radius: 8px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.slider_button {
  position: absolute;
  top: 90%;
  right: 3%;
  border: none;
  cursor: pointer;
  transform: translateY(-50%);
  display: flex;
  gap: 0.8vw;
}
.HomeS6Icon {
  width: 2.5vw;
}

.slider-button:hover {
  background-color: rgba(0, 0, 0, 0.8);
}
.HomeSliderImageS6 {
  width: 40vw;
  height: 100%;
  transition: opacity 0.4s ease-in-out;
  opacity: 0;
}

.HomeSliderImageS6.loaded {
  opacity: 1;
}
.slider_container_mob {
  display: none;
}

.slider_image {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.HomeSliderImageS6.loaded {
  opacity: 1;
}

@media (max-width: 575px) {
  .slider_image {
    width: 100%;
    height: 30vh;
  }
  .slider_image img {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    border-bottom-right-radius: 0;
  }
}

@media (max-width: 575px) {
  .slider_container {
    display: none;
    width: 80vw;
    height: auto;
    margin-top: 3vh;
  }
  .slider_container_mob {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .Home_Slider_Left {
    width: 80vw;
    height: auto;
  }
  .HomeSliderLP1 {
    font-size: 3vw;
  }
  .HomeSliderImageS6 {
    width: 80vw;
    height: 100%;
    transition: opacity 1s ease-in-out;
    opacity: 0;
  }
  .slider_image img {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    border-bottom-right-radius: 0;
  }
  .slider_button {
    position: absolute;
    top: 85%;
    right: 37%;
    gap: 3vw;
  }
  .HomeS6Icon {
    width: 10vw;
  }

  .HomeSliderLP2 {
    display: flex;
    justify-content: space-between;
  }

  .HomeSliderLP2V1 {
    color: var(--white, #fff);
    font-family: SanDiego;
    font-size: 3vw;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0.2px;
    text-transform: capitalize;
    padding-bottom: 0;
    padding-top: 3vh;
  }
  .star_image {
    width: 4vw;
    margin-right: 5px;
  }
  .HomeSliderLP2V2 {
    color: var(--white, #fff);
    font-family: SanDiego;
    font-size: 3vw;
    line-height: 32px; /* 133.333% */
    letter-spacing: 0.2px;
    text-transform: capitalize;
    align-self: flex-end;
  }
}
