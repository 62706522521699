.Slider_Branches {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding: 10vh 0;
}

.Slider_Branches_Text {
  text-align: center;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 2vh;
}
.Slider_B_Head {
  color: var(--gray, #5b7a99);
  font-family: SanDiego;
  font-size: 1.3vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
  text-transform: uppercase;
}
.Slider_B_Head2 {
  color: var(--black, #000);
  font-family: Coco;
  font-size: 2.7vw;
  font-style: normal;
  font-weight: 400;
  line-height: 126%;
  letter-spacing: 0.2px;
  text-transform: uppercase;
}

.Slider_Img_Container {
  position: relative;
  overflow: hidden;
  width: 100%;
  margin: 5vh;
}

.Slider_Branches_Track {
  display: flex;
  animation: slide 60s linear infinite;
  width: max-content;
}

.Slider_Img_Item {
  flex: 0 0 auto;
  margin-right: 10px;
}

.Slider_Img_Item img {
  object-fit: cover;
}

@keyframes slide {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%); /* Move halfway, as the image set is duplicated */
  }
}

/* Hover effect to pause */
.Slider_Img_Container:hover .Slider_Branches_Track {
  animation-play-state: paused;
}
.Slider_Branches_Img {
  width: 35vw;
  border-radius: 8px;
}
@media (max-width: 575px) {
  .Slider_Branches {
    padding: 5vh 0;
  }
  .Slider_B_Head {
    font-size: 3.5vw;
  }
  .Slider_B_Head2 {
    font-size: 5vw;
    /* font-weight: 700; */
  }
  .Slider_Img_Container {
    margin: 0;

    padding: 2vh 0;
  }
  .Slider_Branches_Img {
    width: 80vw;
  }
}
