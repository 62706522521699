.LocationContainer {
  padding: 12vh 0;
}
.LocationS1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2vw;
  padding: 3vw;
  padding-bottom: 0;
}
.LocationS1P1,
.LocationS1P2 {
  display: flex;
  flex-direction: column;
  gap: 2vw;
}
.LocationS1P1L1,
.LocationS1P2L1 {
  color: var(--gray, #5b7a99);
  text-align: center;
  font-family: SanDiego;
  font-size: 1.3vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
  text-transform: uppercase;
}
.LocationS1P1L2 {
  color: var(--black, #000);
  text-align: center;
  font-family: Coco;
  font-size: 3vw;
  font-style: normal;
  font-weight: 400;
  line-height: 123%;
  letter-spacing: 1.2px;
  text-transform: uppercase;
}
.LocationS1P2L2 {
  color: var(--black, #000);
  text-align: center;
  font-family: SanDiego;
  font-size: 2vw;
  font-style: normal;
  font-weight: 700;
  line-height: 123%;
  text-transform: uppercase;
}
@media (max-width: 575px) {
  .LocationContainer {
    padding-bottom: 0;
  }
  .LocationS1P1L1,
  .LocationS1P2L1 {
    font-size: 3.5vw;
  }
  .LocationS1P1L2 {
    font-size: 4vw;
  }
  .LocationS1P2L2 {
    font-size: 3.5vw;
    padding-bottom: 2vh;
  }
}
/*  */
/*  */
/*  */
/*  */
.LocationS2New {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1vw;
  padding: 3vh 0;
}
.LocationImgAll {
  border-radius: 8px;
  height: 100%;
}

.LocationS2NewBoxes {
  display: flex;
  flex-direction: column;
  gap: 2vh;
}
.LocationBoxL1 {
  display: flex;
  align-items: center;
  gap: 1vw;
}
.LocationBoxNew {
  border-radius: 8px;
  background: #f0f3fa;
  padding: 1vw 1.3vw;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  cursor: pointer;
  width: 17rem;

  height: 12.87rem;
  text-decoration: none;
  justify-content: space-between;
}
.LocationBox1L1New {
  width: 2.5vw;
}
.LocationBox1L2 {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  border-radius: 100px;
  background: #ea683b;
  /*  */
  color: var(--white, #fff);
  font-family: SanDiego;
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
  padding: 0.4vw 0.7vw;
}
.LocationBoxL2 {
  color: var(--systemGrey-900, #212121);
  font-family: SanDiego;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  font-size: clamp(1rem, 1vw, 1.3rem);
  font-size: 1rem;
}
.LocationBoxL3 {
  color: var(--gray, #5b7a99);
  font-family: SanDiego;
  font-style: normal;
  font-weight: 400;
  line-height: 124%; /* 24.8px */
  letter-spacing: 0.5px;
  font-size: clamp(0.8rem, 1vw, 0.8rem);
  font-size: 0.8rem;
}
.LocationBoxL4 a,
.LocationBoxL4 a:active,
.LocationBoxL4 a:visited {
  text-decoration: none;
}
.LocationBoxL4Button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5vw;
  border-radius: 100px;
  background: var(--blue, #19499b);
  border: none;
  color: #fff;
  font-family: SanDiego;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: normal;
  letter-spacing: 0.2px;
  cursor: pointer;
  justify-content: center;
  padding: 0.7vh 0.5vw;
  width: 100%;
}
.LocationS2Mob {
  display: none;
}

@media (min-width: 575px) and (max-width: 1200px) {
  .LocationBoxL2 {
    font-size: 1vw;
  }
  .LocationBoxL3 {
    font-size: 1vw;
  }
  .LocationS2NewBoxes {
    gap: 1vh;
  }
  .LocationImgAll {
    width: 48vw;
  }
  .LocationBoxNew {
    height: 9.7rem;
    width: 15rem;
    gap: 0.3rem;
  }
  .LocationBoxL4Button {
  }
}
@media (max-width: 575px) {
  .LocationS2New {
    display: none;
  }

  .LocationS2Mob {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2vh 0;
  }
  .LocationS2MobHead {
    color: var(--black, #000);
    font-family: SanDiego;
    font-size: 4vw;
    font-style: normal;
    font-weight: 500;
    line-height: 124%; /* 24.8px */
    letter-spacing: 0.5px;
    background: #f7f9fb;
    padding: 3vh 0;
    width: 80vw;
    text-align: center;
  }

  .LocationBoxMob {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    margin-top: 2vh;
    border-radius: 8px;
  }

  .LocationBoxL1Mob {
    display: flex;
    align-items: center;
    background: #f0f3fa;
    padding: 2vh 1vh;
    width: 80vw;
    text-align: center;
    justify-content: space-between;
    border-radius: 12px;
  }
  .LocationMobOpen {
    margin-top: 1vh;
  }
  .LocationBoxL2Mob {
    color: #000;
    font-family: SanDiego;
    font-size: 4vw;
    font-style: normal;
    font-weight: 400;
    line-height: 136%; /* 27.2px */
    letter-spacing: 0.2px;
  }
  .LocationBoxL3Mob {
    display: flex;
    padding: 0.5vw 1.2vw;
    justify-content: center;
    align-items: flex-start;
    gap: 4px;
    border-radius: 100px;
    background: #ea683b;
    /*  */
    color: var(--white, #fff);
    font-family: SanDiego;
    font-size: 3.5vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.2px;
  }
  .LocationCardMob {
    background: #f0f3fa;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    width: 80vw;
    margin-top: -1.2vh;
  }
  .LocationCardMobImgCont {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .LocationCardMobImg {
    width: 80.5vw;
    height: 32.3vh;
    border-radius: 8px !important;
  }
  .LocationCardMobSection {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2vh;
  }
  .LocationBoxTCart {
    color: #5b7a99;
    font-family: SanDiego;
    font-size: 3.5vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.2px;
    width: 40vw;
  }

  .LocationBoxL4ButtonMob img {
    margin-right: 5px;
  }
}
