.NotFoundC {
  padding-top: 15vw;
  padding-bottom: 10vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2vw;
  height: 35vw;
}
.NotFoundCT1 {
  color: var(--gray, #5b7a99);
  text-align: center;
  font-family: SanDiego;
  font-size: 3vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
}
.NotFoundCT2 {
  color: var(--black, #000);
  text-align: center;
  font-family: SanDiego;
  font-size: 1.6vw;
  font-style: normal;
  font-weight: 500;
  line-height: 126%; /* 50.4px */
  letter-spacing: 0.2px;
  text-transform: uppercase;
}
.NotFoundCB,
.NotFoundCB:active,
.NotFoundCB:visited {
  text-decoration: none;
}
.NotFoundCP2 {
  display: flex;
  align-items: center;
  gap: 2vw;
}
