.ArtcilePageUnderLine {
  text-decoration: underline;
}
.ArtcilePageFontWeight {
  font-weight: 700;
}
.ArtcilePage1F3Q {
  text-decoration: underline;
  color: black !important;
}
.ArticleBigBold {
  font-size: 2.5vw;
  font-weight: 700;
}
.ArticleS1P2L5Img {
  border-radius: 8px;
  width: 70vw !important;
}
.ArticleOneImg1 {
  width: 65vw;
}
.ArticleOneImg2 {
  width: 65vw;
  height: 50vh;
}
@media (max-width: 575px) {
  .ArticleS1P2L5Img {
    width: 90vw !important;
  }
  .ArticleOneImg1 {
    width: 80vw;
  }
  .ArticleOneImg2 {
    width: 80vw;
    height: 45vw;
  }
  .ArticleBigBold {
    font-size: 4vw;
  }
}
