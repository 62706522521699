.FooterWebContainer {
  padding: 3vw 8vw;
  background: var(--blue-offwhite, #f6f8fb);
  display: flex;
  flex-direction: column;
  gap: 4vh;
}
.FooterWebMain {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 1.3vw;
}
.FooterWebMainS1 {
  color: var(--gray, #5b7a99);
  font-family: SanDiego;
  font-size: 1.5vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
  /* text-transform: uppercase; */
}
.FooterWebMainS2 {
  display: flex;
  align-items: center;
  gap: 1vw;
}
.Footer_Input {
  border-radius: 5px;
  border: none;
  background: #eaf0fd;
  width: 30vw;
  height: 6vh;
  padding: 2.5vh 3vh;
  gap: 10px;
  /*  */
  /* color: #5b7a99; */
  font-family: SanDiego;
  font-size: 1vw;
  font-style: normal;
  font-weight: 400;
  line-height: 124%; /* 24.8px */
  letter-spacing: 0.5px;
}
.Footer_Input:focus {
  outline: none;
  box-shadow: none;
}
.Footer_Main_Button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1vw 1.4vw;
  gap: 4px;
  border-radius: 100px;
  background: var(--blue, #19499b);
  border: none;
  color: #fff;
  font-family: SanDiego, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1vw;
  line-height: normal;
  letter-spacing: 0.2px;
  cursor: pointer;
}
.Footer_Main_ButtonTall {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1vw 1.1vw;
  gap: 4px;
  border-radius: 100px;
  background: var(--blue, #19499b);
  border: none;
  color: #fff;
  font-family: SanDiego, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1vw;
  line-height: normal;
  letter-spacing: 0.2px;
  cursor: pointer;
}
.FooterWebS1 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 0.5fr;
  position: relative;
}
.FooterWebS2 {
  border: 1px solid #000;
  opacity: 0.06;
  background: #000;
}
.FooterWebS3 {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  align-items: center;
  justify-content: center;
}
.FooterWebS1V1 {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.FooterWebS1V1 h3 {
  color: #000;
  font-family: SanDiego;
  font-size: 1.4vw;
  font-style: normal;
  font-weight: 400;
  line-height: 124%;
  letter-spacing: 1px;
  padding-bottom: 10px;
}
.FooterWebS1V1,
.FooterWebS1V1 a,
.FooterWebS1V1 a:active,
.FooterWebS1V1 a:visited {
  color: var(--gray, #5b7a99);
  font-family: SanDiego;
  font-size: 1.2vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
  text-decoration: none;
}
.FooterWebS1V2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 2vh;
}

.FooterButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1vw 2vw;
  gap: 4px;
  border-radius: 100px;
  background: var(--blue, #19499b);
  border: none;
  color: #fff;
  font-family: SanDiego, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1vw;
  line-height: normal;
  letter-spacing: 0.2px;
  cursor: pointer;
}
.FooterWebS3V1Img {
  width: 15vw;
}
.FooterWebS3V2 {
  color: var(--gray, #5b7a99);
  font-family: SanDiego;
  font-size: 1.1vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
  padding-left: 6vw;
}
.Footer_Soical {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1vw;
}
.Footer_Soical_Img {
  width: 3vw;
}
.FooterButtonIcon {
  width: 1.5vw;
}
.FooterWebS1V2Button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5vw;
}
.FooterWebS1V2Button a,
.FooterWebS1V2Button a:visited,
.FooterWebS1V2Button a:active {
  color: var(--blue, #19499b) !important;
}
.HomeS7P1V3Icon {
  width: 1.5vw;
  margin-top: 3px;
}
.FooterWebContainer a,
.FooterWebContainer a:active,
.FooterWebContainer a:visited {
  text-decoration: none;
}
.PhoneNumberTD a {
  border-bottom: 1px solid var(--gray, #5b7a99);
}
