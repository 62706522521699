.Article_Share_Section {
  margin-top: 1vh;
  display: flex;
  align-items: center;
}

.Article_Share_Section span {
  margin-right: 2vw;
  color: #000;
  font-family: SanDiego;
  font-size: 1.3vw;
  font-style: normal;
  font-weight: 400;
  line-height: 136%; /* 27.2px */
  letter-spacing: 0.2px;
}

.Article_Share_Icon {
  width: 3vw;
  margin-right: 10px;
  cursor: pointer;
}
@media (max-width: 575px) {
  .Article_Share_Icon {
    width: 8vw;
  }
  .Article_Share_Section span {
    font-size: 4vw;
  }
}
