.FeedBackMain {
  padding: 12vh 0;
}
.FeedBackS1 {
  display: flex;
  flex-direction: column;
  padding: 5vh 0;
  align-items: center;
  gap: 2vh;
}
.FeedBackS1P1 {
  color: var(--gray, #5b7a99);
  font-family: SanDiego;
  font-size: 1.3vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
  text-transform: uppercase;
}
.FeedBackS1P2 {
  color: var(--black, #000);
  font-family: Coco;
  font-size: 2.2vw;
  font-style: normal;
  font-weight: 400;
  line-height: 126%;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  text-align: center;
}
.FeedBackS1P3 {
  position: relative;
}
.FeedBackS1P3V {
  position: absolute;
  bottom: 5%;
  left: 7%;

  color: #fff;
  font-family: Coco;
  font-size: 2.2vw;
  font-style: normal;
  font-weight: 400;
  line-height: 126%;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  text-align: center;
}
.FeedBackS1P3Img {
  width: 50vw;
  border-radius: 8px;
}
.FeedBackS1P4 {
  color: #a4a8ab;
  text-align: center;
  font-family: SanDiego;
  font-size: 1.1vw;
  font-style: normal;
  font-weight: 400;
  line-height: 124%; /* 24.8px */
  letter-spacing: 0.5px;
  width: 50vw;
}
.FeedBackS1P5 {
  color: #657485;
  text-align: center;
  font-family: SanDiego;
  font-size: 1.1vw;
  font-style: normal;
  font-weight: 400;
  line-height: 124%; /* 24.8px */
  letter-spacing: 0.5px;
}
.FeedBackButton {
  display: flex;
  align-items: center;
  padding: 0.9vw 1.3vw;
  gap: 0.5vw;
  border-radius: 100px;
  background: var(--blue, #19499b);
  border: none;
  color: #fff;
  font-family: SanDiego;
  font-style: normal;
  font-weight: 400;
  font-size: 1vw;
  line-height: normal;
  letter-spacing: 0.2px;
  cursor: pointer;
}
.HeaderButtonImg {
  width: 1.3vw;
}
@media (max-width: 575px) {
  .FeedBackMain {
    padding-bottom: 2vh;
  }
  .FeedBackS1 {
    padding: 0;
  }
  .FeedBackS1P1 {
    font-size: 3.5vw;
  }
  .FeedBackS1P2 {
    font-size: 4vw;
  }
  .FeedBackS1P3Img {
    width: 80vw;
  }
  .FeedBackS1P3V {
    font-size: 3vw;
    left: 12%;
  }
  .FeedBackS1P4 {
    font-size: 3.5vw;
    width: 80vw;
  }
  .FeedBackS1P5 {
    font-size: 3.5vw;
  }
  .FeedBackButton {
    font-size: 5vw;
    padding: 3vw 4vw;
    padding-left: 5vw;
  }
  .FeedBackButtonImg {
    width: 6vw;
  }
}
/*  */
/*  */
/*  */
/*  */
.FeedBackS2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3vh;
  padding: 5vh 0;
}
.FeedBackS2P1 {
  color: var(--black, #000);
  font-family: Coco;
  font-size: 2vw;
  font-style: normal;
  font-weight: 400;
  line-height: 126%;
  letter-spacing: 0.2px;
  text-transform: uppercase;
}
.FeedBackS2P2V {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2vh;
}
.input_field_Feed {
  border-radius: 5px;
  border: none;
  background: #f0f3fa;
  width: 50vw;
  height: 4vh;
  padding: 2.5vh 3vh;
  gap: 10px;
  font-family: SanDiego;
  font-size: 1vw;
  font-style: normal;
  font-weight: 400;
  line-height: 124%;
  letter-spacing: 0.5px;
}
.input__field:focus {
  outline: none;
  box-shadow: none;
}
.input_field_Feed::placeholder {
  color: #5b7a99;
}
.BigFeedBack {
  height: 20vh;
}
.solutionQuestion {
  color: #a4a8ab;
  font-family: SanDiego;
  font-size: 1.1vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
  display: flex;
  align-items: center;
  gap: 0.5vw;
}
@media (max-width: 575px) {
  .FeedBackS2P1 {
    font-size: 4.5vw;
  }
  .input_field_Feed {
    border-radius: 5px;
    border: none;
    background: #f0f3fa;
    width: 80vw;
    height: 4vh;
    padding: 2.5vh 3vh;
    gap: 10px;
    font-family: SanDiego;
    font-size: 3.5vw;
    font-style: normal;
    font-weight: 400;
    line-height: 124%;
    letter-spacing: 0.5px;
    color: #5b7a99;
  }
  .input_field_Feed::placeholder {
    color: #5b7a99;
  }

  .BigFeedBack {
    height: 25vh;
  }
}
.FeedBackS3 {
  background: linear-gradient(180deg, #f6f8fb 0%, #fff 100%) !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5vw 0;
  gap: 3vw;
  padding-bottom: 0;
}
.FeedBackS3P1 {
  color: var(--black, #000);
  text-align: center;
  font-family: Coco;
  font-size: 2.1vw;
  font-style: normal;
  font-weight: 700;
  line-height: 126%;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  width: 60vw;
}
.captchaModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.captchaModalContent {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 300px;
  max-width: 90%;
}

.captchaModalContent h3 {
  margin-bottom: 15px;
  font-size: 18px;
  color: #333;
}

.captchaModalContent p {
  margin-bottom: 20px;
  font-size: 16px;
  color: #666;
}

.captchaModalContent input {
  width: 91%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.captchaModalContent button {
  width: 100%;
  padding: 10px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.captchaModalContent button:hover {
  background-color: #45a049;
}

.captchaModalContent button:last-child {
  background-color: #f44336;
  margin-top: 10px;
}

.captchaModalContent button:last-child:hover {
  background-color: #e53935;
}
@media (max-width: 575px) {   
  .FeedBackS3P1 {
    font-size: 4vw;
    width: 80vw;
    padding: 2vw 0;
  }
}
