.WashClubMain {
  padding: 12vh 0;
  padding-bottom: 0;
}
.WashClubS1 {
  padding: 5vh 5vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2vh;
}
.WashClubS1P1 {
  color: var(--gray, #5b7a99);
  font-family: SanDiego;
  font-size: 1.3vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
  text-transform: uppercase;
}
.WashClubS1P2Img {
  width: 8vw;
}
.WashClubS1P3 {
  color: var(--black, #000);
  font-family: Coco;
  font-size: 2.7vw;
  font-style: normal;
  font-weight: 400;
  line-height: 126%;
  letter-spacing: 0.2px;
  text-transform: uppercase;
}
.WashClubS1P4 {
  color: var(--gray, #5b7a99);
  font-family: SanDiego;
  font-size: 1.3vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
  width: 65vw;
  text-align: center;
}
@media (max-width: 575px) {
  .WashClubS1 {
    padding: 5vh 5vw;
    gap: 1.5vh;
  }
  .WashClubS1P1 {
    font-size: 3.5vw;
  }
  .WashClubS1P2Img {
    width: 30vw;
  }
  .WashClubS1P3 {
    font-size: 4vw;
  }
  .WashClubS1P4 {
    font-size: 3.5vw;
    width: 80vw;
  }
}
.WashClubS2 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 7vh 5vw;
}
.WashClubS2Part {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2vh;
}
.WashClubS2PartV1 {
  color: var(--black, #000);
  font-family: Coco;
  font-size: 2vw;
  font-style: normal;
  font-weight: 700;
  line-height: 126%;
  text-transform: uppercase;
}
.WashClubS2PartV2 {
  color: var(--gray, #5b7a99);
  font-family: SanDiego;
  font-size: 1vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
  text-align: center;
  width: 17vw;
}
.WashClubS2PartV2 a,
.WashClubS2PartV2 a:active,
.WashClubS2PartV2 a:visited {
  color: var(--blue, #19499b);
  text-decoration: none;
}
@media (max-width: 575px) {
  .WashClubS2 {
    grid-template-columns: 1fr;
    padding: 0;
    gap: 4vh;
  }
  .WashClubS2Part {
    gap: 1vh;
  }
  .WashClubS2PartV1 {
    font-size: 4vw;
  }
  .WashClubS2PartV2 {
    font-size: 3.5vw;
    width: 80vw;
  }
}

.WashClubS2New {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4vw;
  padding: 3vh 4vw;
}
.WCS2NP2 {
  display: flex;
  flex-direction: column;
  gap: 3vh;
}
.WCS2NP2VF {
  display: flex;
  align-items: center;
  gap: 0.5vw;
}
.WCS2NP2VFImg {
  width: clamp(1.5rem, 2vw, 2.5rem);
}
.WCS2NP1Img {
  width: 11vw;
  width: clamp(10rem, 14.5vw, 14.5rem);
}
.WCS2NP2VFT {
  color: var(--black, #000);
  font-family: SanDiego;
  font-size: 1.5vw;
  font-size: clamp(1.3rem, 2vw, 2rem);
  font-style: normal;
  font-weight: 500;
  line-height: 126%;
  text-transform: uppercase;
}
.WCS2NP2VT {
  color: var(--gray, #5b7a99);
  font-family: SanDiego;
  font-size: clamp(0.8rem, 1.26vw, 1.26rem);
  font-style: normal;
  font-weight: 400;
  line-height: 124%;
  letter-spacing: 0.5px;
}
@media (max-width: 575px) {
  .WashClubS2New {
    flex-direction: column-reverse;
    gap: 4vh;
    padding-top: 0;
  }
  .WCS2NP2 {
    padding: 0 3vw;
    gap: 4vh;
  }
  .WCS2NP2V {
    display: flex;
    flex-direction: column;
    gap: 1vh;
  }
  .WCS2NP2VF {
    /* remove */
    justify-content: center;
  }
  .WCS2NP2VFT {
    font-size: 5vw;
  }
  .WCS2NP2VT {
    font-size: 4vw;
    /* remove */
    text-align: center;
  }
}

/*  */
/*  */
/*  */
/*  */
/*  */
.WashClubS3 {
  display: flex;
  flex-direction: column;
  gap: 4vh;
  align-items: center;
  padding-bottom: 10vh;
}
.WashClubS3P1Img {
  width: 15vw;
}
.WashClubS3P2Img {
  width: 10vw;
}
.WashClubS3P2 {
  display: flex;
  gap: 2vw;
}
@media (max-width: 575px) {
  .WashClubS3 {
    gap: 3vh;
    padding: 5vh 0;
  }
  .WashClubS3P1Img {
    display: none;
  }
  .WashClubS3P2 {
    display: flex;
    flex-direction: column;
    gap: 2vw;
  }
  .WashClubS3P2Img {
    width: 40vw;
  }
}
.WashClubS4 {
  padding: 5vh 5vh;
  background: #5b7a99;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3vh;
}
.WashClubS4P1 {
  color: #fff;
  font-family: Coco;
  font-size: 2vw;
  font-style: normal;
  font-weight: 500;
  line-height: 126%;
  text-transform: uppercase;
}
.WashClubS4P2 {
  color: #fff;
  font-family: SanDiego;
  font-size: 1.3vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
  text-transform: uppercase;
}
.WashClubS4P3B {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.7vw 2vw;
  gap: 4px;
  border-radius: 100px;
  background: var(--blue, #19499b);
  border: none;
  color: #fff;
  font-family: SanDiego, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.2vw;
  line-height: normal;
  letter-spacing: 0.2px;
  cursor: pointer;
}
.WashClubS4P3 a,
.WashClubS4P3 a:visited,
.WashClubS4P3 a:active {
  text-decoration: none;
}
.WashClubS4P4,
.WashClubS4P4 a,
.WashClubS4P4 a:active,
.WashClubS4P4 a:visited {
  color: #fff;
  font-family: SanDiego;
  font-size: 1.3vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  text-decoration: none;
}
.WashClubS4P5 {
  color: #fff;
  font-family: SanDiego;
  font-size: 1.3vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
  text-transform: uppercase;
}
@media (max-width: 575px) {
  .WashClubS4P1 {
    font-size: 4vw;
  }
  .WashClubS4P2 {
    font-size: 3.5vw;
    text-align: center;
  }
  .WashClubS4P3B {
    padding: 1.5vw 3.5vw;
    font-size: 4vw;
  }
  .WashClubS4P4,
  .WashClubS4P4 a,
  .WashClubS4P4 a:active,
  .WashClubS4P4 a:visited {
    font-size: 3.5vw;
  }
  .WashClubS4P5 {
    font-size: 3.5vw;
  }
}
