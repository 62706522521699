.SmallCartS1 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.SmallCartS1P1 {
  color: var(--gray, #5b7a99);
  text-align: center;
  font-family: SanDiego;
  font-size: 1.2vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}
.SmallCartS1P2 {
  color: var(--black, #000);
  text-align: center;
  font-family: Coco;
  font-size: 2vw;
  font-style: normal;
  font-weight: 400;
  line-height: 126%; /* 50.4px */
  letter-spacing: 0.2px;
  text-transform: uppercase;
}
/*  */
.SmallCartS2 {
  display: flex;
  flex-direction: column;
  /* width: 20vw; */
}
/*  */
.SmallCartS2P1 {
  background: #f0f3fa;
  z-index: 1;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 2fr;
  padding: 2vw;
}
.SmallCartS2P1Head {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2vh;
}
.SmallCartS2P1HeadL1 {
  display: flex;
  padding: 1vw 2vh;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 50px;
  border: 1px solid #c9d5e3;
  /* for text */
  color: var(--blue, #19499b);
  text-align: center;
  font-family: SanDiego;
  font-size: 1vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
  text-transform: uppercase;
}
.SmallCartS2P1HeadL2 {
  color: var(--gray, #5b7a99);
  font-family: SanDiego;
  font-size: 1vw;
  font-style: normal;
  font-weight: 400;
  line-height: 124%; /* 24.8px */
  letter-spacing: 0.5px;
}
.SmallCartS2P1HeadL3Border {
  background: #dde6f0;
  height: 0.2vh;
  width: 100%;
}
/*  */
.SmallCartS2P1Items {
  display: grid;
  grid-template-columns: 1fr;
  padding-top: 2vh;
}
.SmallCartS2P1ItemsR {
  justify-self: flex-end;
}
.SmallCItem {
  display: flex;
  align-items: center;
  gap: 0.5vw;
}
.SmallCItemText {
  color: var(--gray, #5b7a99);
  font-family: SanDiego;
  font-size: 1vw;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.5px;
}
/*  */
.SmallCartS2P2Img {
  /* z-index: -1; */
  width: 100%;
  height: 100%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
/*  */
.SmallCItemImg {
  width: 1.5vw;
}
