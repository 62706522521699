.HeaderMob_Absoulte {
  padding: 5vw 7vw;
  padding-bottom: 2vw;
  /* padding-left: 10vw; */
  position: fixed;
  top: 0;
  width: 100%;
  transition: all 0.5s ease-in-out;
  z-index: 10000000;
  background-color: #fff;
}
.scrolled {
  background-color: white;
  padding-bottom: 0.5vw;
}
.HeaderMob {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10vw;
  z-index: 10;
}
.HeaderMobImg {
  width: 40vw;
}
.HeaderMobRoute {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2vw;
}
.HeaderMobRoute a,
.HeaderMobRoute a:visited,
.HeaderMobRoute a:active {
  text-decoration: none;
  padding-bottom: 5px;
  font-size: 5vw;
  color: #000;
  font-family: SanDiego, sans-serif;
}
.HeaderMobButton {
  display: flex;
  align-items: center;
  padding: 4vw 12vw;
  gap: 0.5vw;
  border-radius: 100px;
  background: var(--blue, #19499b);
  border: none;
  color: #fff;
  font-family: SanDiego;
  font-style: normal;
  font-weight: 400;
  font-size: 3.5vw;
  line-height: normal;
  letter-spacing: 0.2px;
  cursor: pointer;
}
.HeaderMobButtonImg {
  width: 5vw;
}
.scrolled_link_Mob {
  color: #000 !important;
}
.active_link_Mob,
.active_link_Mob a,
.active_link_Mob a:visited,
.active_link_Mob a:active {
  color: #6088ff !important;
  padding-bottom: 5px !important;
  border-bottom: 3px solid #6088ff;
}
.WashClubContMob {
  position: relative;
}
.WashClubSvgMob {
  position: absolute;
  left: 77%;
  top: -25%;
  width: 7vw;
}
.DropdownToggleButton {
  background: none;
  border: none;
  cursor: pointer;
}

.DropdownToggleButton img {
  width: 9vw;
}

.DropdownMenu {
  display: flex;
  flex-direction: column;
  position: absolute;
  gap: 0.5vh;
  align-items: center;
  top: 100%;
  left: 7%;
  right: 0;
  background-color: white;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  padding: 10px;
  z-index: 10;
  width: 87%;
  border-radius: 8px;
}

.DropdownMenu a {
  padding: 10px;
  color: #333;
  text-decoration: none;
  transition: background 0.3s;
}

.DropdownMenu a:hover {
  background: #f0f0f0;
}
.HeaderHomeMobIcon {
  width: 9vw;
}
