.BlogContainer {
  padding: 12vh 0;
}
.BlogS1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5vw 10vw;
  text-align: center;
  gap: 1.5vw;
}
.BlogS1P1 {
  color: var(--gray, #5b7a99);
  text-align: center;
  font-family: SanDiego;
  font-size: 1.2vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
  text-transform: uppercase;
}
.BlogS1P2 {
  color: var(--black, #000);
  text-align: center;
  font-family: Coco;
  font-size: 2.8vw;
  font-style: normal;
  font-weight: 400;
  line-height: 123%;
  letter-spacing: 1.2px;
  text-transform: uppercase;
}
.BlogS1P3 {
  color: var(--gray, #5b7a99);
  text-align: center;
  font-family: SanDiego;
  font-size: 1.1vw;
  font-style: normal;
  font-weight: 400;
  line-height: 124%; /* 24.8px */
  letter-spacing: 0.5px;
}

.BlogS2 {
  display: flex;
  justify-content: center;
}

.BlogS2P4 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 6vw;
  padding: 0 5vw;
}
@media (max-width: 575px) {
  .BlogContainer {
    padding-bottom: 2vh;
  }
  .BlogS1P1 {
    font-size: 3vw;
    padding-bottom: 1vh;
  }

  .BlogS1P2 {
    font-size: 4.5vw;
    padding-bottom: 1vh;
  }

  .BlogS1P3 {
    font-size: 3vw;
  }
  .BlogS2P4 {
    grid-template-columns: 1fr;
  }
}

/*  */
/*  */
.BlogS3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.5vw;
  padding-top: 15vh;
}

.BlogS3P1Icon {
  width: 7vw;
}
.BlogS3P2 {
  color: var(--gray, #5b7a99);
  text-align: center;
  font-family: SanDiego;
  font-size: 1.2vw;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: 0.2px;
  text-transform: uppercase;
}
.BlogS3P3 {
  color: var(--black, #000);
  text-align: center;
  font-family: SanDiego;
  font-size: 2.2vw;
  font-style: normal;
  font-weight: 700;
  line-height: 126%; /* 40.32px */
  text-transform: uppercase;
}
.BlogS3P4 {
  color: var(--gray, #5b7a99);
  font-family: SanDiego;
  font-size: 1.3vw;
  font-style: normal;
  font-weight: 400;
  line-height: 124%; /* 24.8px */
  letter-spacing: 0.5px;
  text-align: center;
}

.BlogS3P5,
.BlogS3P5 a,
.BlogS3P5 a:active,
.BlogS3P5 a:visited {
  color: var(--blue, #19499b);
  font-family: SanDiego;
  font-size: 1.1vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
  display: flex;
  text-decoration: none;
  text-align: left;
  padding-top: 1vw;
  /* padding-left: 3px; */
}
.BlogS3P5Icon {
  width: 1.5vw;
}
@media (max-width: 575px) {
  .BlogS3 {
    gap: 2.5vw;
    padding-top: 5vh;
  }

  .BlogS3P1Icon {
    width: 20vw;
  }
  .BlogS3P2 {
    font-size: 3.5vw;
  }
  .BlogS3P3 {
    font-size: 4vw;
    font-weight: 500;
  }
  .BlogS3P4 {
    color: var(--gray, #5b7a99);
    font-family: SanDiego;
    font-size: 3.5vw;
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 24.8px */
    letter-spacing: 0.5px;
    text-align: center;
    width: 80vw;
  }
  .BlogS3P4 br {
    display: none;
  }

  .BlogS3P5,
  .BlogS3P5 a,
  .BlogS3P5 a:active,
  .BlogS3P5 a:visited {
    font-size: 3.5vw;
  }
  .BlogS3P5Icon {
    width: 4vw;
  }
}
