.ArticlePageContainer {
  padding: 0 5vw;
  padding-top: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5vh;
}
.ArticleS1Container {
  width: 100%;
}
.ArticleS1P1 {
  padding-bottom: 5vh;
}

.ArticleS1P1Button,
.ArticleS1P1Button a,
.ArticleS1P1Button a:active,
.ArticleS1P1Button a:visited {
  color: var(--blue, #19499b);
  font-family: SanDiego;
  font-size: 1.1vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
  display: flex;
  text-decoration: none;
  align-items: center;
  text-align: left;
  justify-content: flex-start;
}
.ArticleS1P1Icon {
  width: 1.5vw;
}

.ArticleS1P2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2vh;
}
.ArticleS1P2L1 {
  color: var(--gray, #5b7a99);
  text-align: center;
  font-family: SanDiego;
  font-size: 1.3vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}
.ArticleS1P2L2,
.ArticleS1P2L4 {
  color: var(--gray, #5b7a99);
  text-align: center;
  font-family: SanDiego;
  font-size: 1vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.5px;
}
.ArticleS1P2L3 {
  color: var(--black, #000);
  text-align: center;
  font-family: SanDiego;
  font-size: 3vw;
  font-style: normal;
  font-weight: 400;
  line-height: 123%; /* 59.04px */
  letter-spacing: 1.2px;
  text-transform: uppercase;
}
.ArticleS1P2L5Img {
  width: 70vw;
}

@media (max-width: 575px) {
  .ArticlePageContainer {
    padding-top: 12vh;
  }
  .ArticleS1P1Button,
  .ArticleS1P1Button a,
  .ArticleS1P1Button a:active,
  .ArticleS1P1Button a:visited {
    font-size: 3.5vw;
  }
  .ArticleS1P1Icon {
    width: 4vw;
  }
  .ArticleS1P2L1 {
    font-size: 3.5vw;
  }
  .ArticleS1P2L2,
  .ArticleS1P2L4 {
    font-size: 3.5vw;
  }
  .ArticleS1P2L3 {
    font-size: 5vw;
  }
  .ArticleS1P2L5Img {
    width: 90vw;
    height: auto;
  }
}

/*  */
/*  */
/*  */
.ArticleS2 {
  display: flex;
  flex-direction: column;
  width: 70vw;
  gap: 5vh;
}
.ArticleS2P1 {
  color: var(--gray, #5b7a99);
  text-align: left;
  font-family: SanDiego;
  font-size: 1.2vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}
.ArticleS2P2 {
  color: var(--black, #000);
  font-family: SanDiego;
  font-size: 1.2vw;
  font-style: normal;
  font-weight: 400;
  line-height: 122%;
  letter-spacing: 0.5px;
}
.ArticleS3Container {
  display: flex;
  flex-direction: column;
  padding: 10vh 0;
  gap: 5vh;
  align-items: center;
  justify-content: center;
}
.ArticleS3Head {
  color: var(--systemGrey-900, #212121);
  text-align: center;
  font-family: Coco;
  font-size: 2vw;
  font-style: normal;
  font-weight: 400;
  line-height: 126%; /* 50.4px */
  letter-spacing: 0.2px;
  text-transform: uppercase;
}
.ArticleS3 {
  display: flex;
  gap: 6vw;
}
@media (max-width: 575px) {
  .ArticleS2 {
    width: 85vw;
    gap: 2vh;
  }
  .ArticleS2P1 {
    font-size: 3.5vw;
  }
  .ArticleS2P2 {
    color: var(--black, #000);
    font-family: SanDiego;
    font-size: 3.5vw;
    font-style: normal;
    font-weight: 400;
    line-height: 122%;
    letter-spacing: 0.5px;
  }
  .ArticleS3 {
    flex-direction: column;
  }
  .ArticleS3Container {
    padding: 5vh 0;
  }
  .ArticleS3Head {
    font-size: 4vw;
  }
}
