.Services_Container {
  padding: 12vh 0;
}
.ServicesS1 {
  padding: 5vw 10vw;
}
.ServicesS1Com {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3vw;
}
.ServicesS3P1Img {
  width: 40vw;
  border-radius: 8px;
}

.ServicesS1P2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2vw;
}
.ServicesS1P2V1 {
  color: var(--gray, #5b7a99);
  font-family: SanDiego;
  font-size: 1.2vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}
.ServicesS1P2V2 {
  color: var(--systemGrey-900, #212121);
  font-family: Coco;
  font-size: 2vw;
  font-style: normal;
  font-weight: 500;
  line-height: 123%;
  letter-spacing: 0.2px;
  text-transform: uppercase;
}
.ServicesS1P2V3 {
  color: var(--gray, #5b7a99);
  font-family: SanDiego;
  font-size: 1.2vw;
  font-style: normal;
  font-weight: 400;
  line-height: 124%;
  letter-spacing: 0.5px;
}
.ServicesS1P2V3 span {
  font-size: 1.5vw;
  font-family: Coco;
}
.ServicesS1Mob {
  display: none;
}

.ServicesS1New {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10vh;
}
.ServicesS1NewP1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2vh;
}
.ServicesS1NewP1V1 {
  color: var(--gray, #5b7a99);
  font-family: SanDiego;
  font-size: 1.2vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  text-align: center;
}
.ServicesS1NewP1V2 {
  color: var(--systemGrey-900, #212121);
  font-family: Coco;
  font-size: 2vw;
  font-style: normal;
  font-weight: 500;
  line-height: 123%;
  letter-spacing: 0.2px;
  text-transform: uppercase;
}
.ServicesS1NewP2 {
  display: flex;
  gap: 3vw;
}
.ServicesS1NewP2V1Img {
  width: 30vw;
  border-radius: 8px;
}

.ServicesS1NewP2V2 {
  display: flex;
  flex-direction: column;
  gap: 2vh;
  justify-content: center;
}
.ServicesS1NewP2V2T1 {
  display: flex;
  align-items: center;
  gap: 2vw;
  color: var(--systemGrey-900, #212121);
  font-family: SanDiego;
  font-size: 1.8vw;
  font-style: normal;
  font-weight: 500;
  line-height: 126%; /* 40.32px */
  text-transform: uppercase;
}
.ServicesS1NewP2V2T2 {
  color: var(--gray, #5b7a99);
  font-family: SanDiego;
  font-size: 1vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.5px;
  width: 30vw;
}
.ServicesS1NewMob {
  display: none;
}

@media (max-width: 575px) {
  .Services_Container {
    padding-bottom: 0;
  }
  .ServicesS1 {
    display: none;
  }
  .ServicesS1Mob {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ServicesS1P2 {
    align-items: center;
  }
  .ServicesS1P2V1 {
    font-size: 3.5vw;
  }
  .ServicesS1P2V2 {
    font-size: 5vw;
  }
  .ServicesS3P1Img {
    width: 80vw;
  }
  .ServicesS1P2V3 {
    font-size: 3.5vw;
    text-align: center;
  }
  .ServicesS1NewWeb {
    display: none;
  }
  .ServicesS1NewMob {
    display: flex;
  }
  .ServicesS1New {
    gap: 3vh;
    padding-bottom: 5vh;
  }
  .ServicesS1NewP1V1 {
    font-size: 3.5vw;
    padding-top: 3vh;
  }
  .ServicesS1NewP1V2 {
    font-size: 5vw;
  }
  .ServicesS1NewP2V1Img {
    width: 80vw;
  }
  .ServicesS1NewP2V2T1 {
    font-size: 4vw;
  }
  .ServicesS1NewP2V2T2 {
    font-size: 3.5vw;
    width: 80vw;
  }
}

/*  */
/*  */
/*  */
.ServicesS2 {
  padding: 5vw 10vw;
  display: flex;
  flex-direction: column;
  gap: 3vw;
}
.ServicesS2P1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 1.5vw;
}
.ServicesS2P1V1 {
  color: var(--gray, #5b7a99);
  font-family: SanDiego;
  font-size: 1vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
  text-transform: uppercase;
}
.ServicesS2P1V2 {
  color: var(--systemGrey-900, #212121);
  text-align: center;
  font-family: Coco;
  font-size: 2.5vw;
  font-style: normal;
  font-weight: 500;
  line-height: 123%; /* 59.04px */
  letter-spacing: 1.2px;
  text-transform: uppercase;
}
.ServicesS2P1V3 {
  color: var(--gray, #5b7a99);
  font-family: SanDiego;
  font-size: 1.2vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
}
.ServicesS2P2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.ServicesS2P2Img {
  width: 70vw;
}
@media (max-width: 575px) {
  .ServicesS2P1V1 {
    font-size: 3.5vw;
  }
  .ServicesS2P1V2 {
    font-size: 4vw;
  }
  .ServicesS2P1V3 {
    font-size: 3.5vw;
  }
}
/*  */
/*  */
/*  */
/*  */

.ServicesS3 {
  background: linear-gradient(180deg, #f6f8fb 0%, #fff 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5vw 0;
  gap: 3vw;
}
.ServicesS3P1 {
  display: flex;
  flex-direction: column;
  gap: 3vh;
  text-align: center;
}
.ServicesS3P1 h1 {
  color: var(--gray, #5b7a99);
  text-align: center;
  font-family: SanDiego;
  font-size: 1.5vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
  text-transform: uppercase;
}
.ServicesS3P1 h2 {
  color: var(--black, #000);
  text-align: center;
  font-family: Coco;
  font-size: 3vw;
  font-style: normal;
  font-weight: 400;
  line-height: 126%; /* 50.4px */
  letter-spacing: 0.2px;
  text-transform: uppercase;
}
.ServicesS3P1 h2 br {
  display: none;
}
.ServicesS3P2 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 5vw;
}
.ServicesS3P2B1 {
  position: relative;
  display: flex;
  flex-direction: column;
}
.ServicesS3P2B1::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 68.58%, #19499b 100%);
  z-index: 1;
  pointer-events: none;
  z-index: 2;
}
.ServicesS3P2B1Img {
  width: 25vw;
  border-radius: 8px;
}

.ServicesS3P2B1H1 {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--white, #fff);
  text-align: center;
  font-family: SanDiego;
  font-size: 2vw;
  font-style: normal;
  font-weight: 500;
  line-height: 126%;
  text-transform: uppercase;
  width: 100%;
  z-index: 3;
}
.ServicesS3P2B1 {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
.ServicesS3P2B1H2 {
  /* for button style */
  border-radius: 100px;
  background: #ea683b;
  border: none;
  display: flex;
  padding: 0.3vw 1vw;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  /* for text style */
  color: var(--white, #fff);
  font-family: SanDiego;
  font-size: 1vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
  /* for position on image */
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
}
.ServicesS3P2B1 a,
.ServicesS3P2B1 a:visited,
.ServicesS3P2B1 a:active {
  text-decoration: none;
  cursor: pointer;
}
.ServicesS3P2B1Button {
  display: flex;
  padding: 1vw 0.5vw;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 100px;
  background: var(--blue, #19499b);
  border: none;
  /* for text */
  color: #fff;
  font-family: SanDiego;
  font-size: 1vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
  /* for poistion */
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  cursor: pointer;
}
.ServicesS3RightImg {
  width: 2vw;
}

@media (max-width: 575px) {
  .ServicesS3P2 {
    grid-template-columns: 1fr;
    padding-bottom: 3vh;
  }
  .ServicesS3P1 h1 {
    font-size: 4vw;
  }
  .ServicesS3P1 h2 {
    font-size: 4vw;
  }
  .ServicesS3P1 h2 br {
    display: block;
  }
  .ServicesS3P2B1Img {
    width: 80.7vw;
    height: 32.3vh;
  }
  .ServicesS3P2B1H1 {
    font-size: 4vw;
    top: 10%;
  }
  .ServicesS3P2B1H2 {
    font-size: 3vw;
    top: 20%;
  }
  .ServicesS3P2B1Button {
    font-size: 4vw;
    padding: 3vw 1vw;
    width: 55%;
    bottom: -5%;
    left: 50%;
    padding-left: 1.5vw;
  }
  .ServicesS3RightImg {
    width: 5vw;
    padding-top: 0.7vw;
  }
}
/*  */
/*  */
/*  */
/*  */

.ServicesS4 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 5vw 10vw;
  background-image: url("../../Assets/svg/About/AboutS5BG.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;

  gap: 2vw;
}
.ServicesS4P1 {
  color: var(--gray, #5b7a99);
  font-family: SanDiego;
  font-size: 1.2vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}
.ServicesS4P2 {
  color: var(--systemGrey-900, #212121);
  font-family: Coco;
  font-size: 2vw;
  font-style: normal;
  font-weight: 500;
  line-height: 123%;
  letter-spacing: 0.2px;
  text-transform: uppercase;
}
.ServicesS4P3 button {
  display: flex;
  align-items: center;
  padding: 1vw 2vw;
  border-radius: 100px;
  background: #19499b;
  border: none;
  color: #f6f8fb;
  font-family: SanDiego;
  font-size: 1vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
  cursor: pointer;
}
.ServicesS4P3 a,
.ServicesS4P3 a:active,
.ServicesS4P3 a:visited {
  text-decoration: none;
}
@media (max-width: 575px) {
  .ServicesS4 {
    background-image: url("../../Assets/svg/About/AboutS5BGMOB.svg");
    background-size: contain;
    gap: 4vw;
    height: 37vh;
  }
  .ServicesS4P1 {
    font-size: 3.5vw;
  }
  .ServicesS4P2 {
    font-size: 4vw;
  }
  .ServicesS4P3 {
    padding-top: 3vh;
  }
  .ServicesS4P3 button {
    padding: 2vw 5vw;
    font-size: 4vw;
  }
}
