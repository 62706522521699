.ContactUsMain {
  padding: 12vh 0;
  padding-bottom: 0;
}
.ContactUsBig {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10vh;
  gap: 3vh;
}
.ContactUsS1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10vh;
  padding: 5vh 0;
}
.ContactUsS1P1 {
  color: var(--black, #000);
  font-family: SanDiego;
  font-size: 2vw;
  font-style: normal;
  font-weight: 400;
  line-height: 126%;
  letter-spacing: 0.2px;
  text-transform: uppercase;
}
.ContactUsS1P2V {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2vh;
}
.input__field {
  border-radius: 5px;
  border: none;
  background: #f0f3fa;
  width: 30vw;
  height: 4vh;
  padding: 2.5vh 3vh;
  gap: 10px;
  /* color: #5b7a99; */
  font-family: SanDiego;
  font-size: 1vw;
  font-style: normal;
  font-weight: 400;
  line-height: 124%;
  letter-spacing: 0.5px;
}
.input__field::placeholder {
  color: #5b7a99;
}
.input__field:focus {
  outline: none;
  box-shadow: none;
}
.BigContactUs {
  height: 20vh;
  min-height: 20vh;
  max-height: 20vh;
  min-width: 30vw;
  max-width: 30vw;
}
.BigContactUs {
  resize: none;
}
.solutionQuestion {
  color: #a4a8ab;
  font-family: SanDiego;
  font-size: 1.1vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
  display: flex;
  align-items: center;
  gap: 0.5vw;
}

.ContactUsS3 {
  background: linear-gradient(180deg, #f6f8fb 0%, #fff 100%) !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5vw 0;
  gap: 3vw;
}
.ContactUsS3P1 {
  color: var(--black, #000);
  text-align: center;
  font-family: Coco;
  font-size: 2.1vw;
  font-style: normal;
  font-weight: 700;
  line-height: 126%;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  width: 60vw;
}
.captchaModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.captchaModalContent {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 20vw;
  max-width: 90%;
}

.captchaModalContent h3 {
  margin-bottom: 15px;
  font-size: 18px;
  color: #333;
  font-family: SanDiego;
  font-size: 1vw;
  font-style: normal;
  font-weight: 400;
  line-height: 124%;
  letter-spacing: 0.5px;
}

.captchaModalContent p {
  margin-bottom: 20px;
  color: #666;
  font-family: SanDiego;
  font-size: 1vw;
  font-style: normal;
  font-weight: 400;
  line-height: 124%;
  letter-spacing: 0.5px;
}

.captchaModalContent input {
  width: 91%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  font-family: SanDiego;
  font-size: 1vw;
  font-style: normal;
  font-weight: 400;
  line-height: 124%;
  letter-spacing: 0.5px;
}

.captchaModalContent button {
  width: 100%;
  padding: 10px;
  background: var(--blue, #19499b);
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-family: SanDiego;
  font-size: 1vw;
  font-style: normal;
  font-weight: 400;
  line-height: 124%;
  letter-spacing: 0.5px;
}

.captchaModalContent button:hover {
  background: var(--blue-offwhite, #f6f8fb);
  color: #5b7a99;
}

.captchaModalContent button:last-child {
  background-color: #f44336;
  color: #fff;
  margin-top: 10px;
}

.captchaModalContent button:last-child:hover {
  background-color: #e53935;
}
.ContactUsS2 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 3vh;
  padding-top: 5vh;
}
.ContactUsS2P1 {
  color: var(--black, #000);
  font-family: SanDiego;
  font-size: 1.5vw;
  font-style: normal;
  font-weight: 400;
  line-height: 126%;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  text-align: center;
}
.ContactUsS2P2 {
  display: flex;
  flex-direction: column;
  gap: 2vh;
}
.ContactUsS2P2 a :active,
.ContactUsS2P2 a:visited,
.ContactUsS2P2 a {
  text-decoration: none;
}
.ContactUsS2P2V {
  display: flex;
  gap: 1vw;
  align-items: center;
}
.ContactUsS2P2VText {
  color: var(--gray, #5b7a99);
  font-family: SanDiego;
  font-size: 1vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
}
.ContactUsS2Svg {
  width: 2.5vw;
}
.ContactUsS {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 4vw;
}

.ContactSignSend {
  display: flex;
  flex-direction: column;
  gap: 2vh;
  align-items: center;
}
.ContactUsBigMob {
  display: none;
}

.ContactButton {
  display: flex;
  align-items: center;
  padding: 0.9vw 1.3vw;
  gap: 0.5vw;
  border-radius: 100px;
  background: var(--blue, #19499b);
  border: none;
  color: #fff;
  font-family: SanDiego;
  font-style: normal;
  font-weight: 400;
  font-size: 1vw;
  line-height: normal;
  letter-spacing: 0.2px;
  cursor: pointer;
}
.ContactButtonImg {
  width: 1.3vw;
}
@media (max-width: 575px) {
  .captchaModalContent {
    width: 60vw;
  }
  .captchaModalContent h3 {
    font-size: 4vw;
  }
  .captchaModalContent input {
    font-size: 3vw;
  }
  .captchaModalContent button {
    font-size: 4vw;
  }
  .captchaModalContent p {
    font-size: 4vw;
  }
  .ContactUsMain {
    padding-bottom: 0;
  }
  .ContactUsBig {
    display: none;
  }
  .ContactUsBigMob {
    display: flex;
  }
  .ContactUsS {
    flex-direction: column;
    align-items: center;
    gap: 4vh;
  }
  .ContactUsBig {
    padding: 0;
    gap: 1vh;
  }
  .ContactUsS1P1 {
    font-size: 4vw;
  }
  .ContactUsS1 {
    padding: 0;
    padding-top: 4vh;
  }
  .input__field {
    width: 80vw;
    height: 3vh;
    padding: 2.5vh 3vh;
    gap: 10px;
    color: #5b7a99;
    font-size: 3.5vw;
  }
  .BigContactUs {
    height: 20vh;
    min-width: 80vw;
    max-width: 80vw;
  }
  .solutionQuestion {
    font-size: 3.5vw;
    text-align: center;
    width: 80vw;
  }
  .ContactButton {
    font-size: 5vw;
    padding: 3vw 4vw;
    padding-left: 5vw;
  }
  .ContactButtonImg {
    width: 6vw;
  }
  .ContactUsS2 {
    padding-top: 2vh;
    padding-bottom: 2vh;
  }
  .ContactUsS2P1 {
    font-size: 4vw;
  }
  .ContactUsS2P2 {
    gap: 4vh;
    padding-bottom: 2vh;
  }
  .ContactUsS2P2V {
    gap: 3vw;
    width: 80vw;
  }
  .ContactUsS2P2VText {
    font-size: 5vw;
  }
  .ContactUsS2Svg {
    width: 10vw;
  }
}
.ContactUsS1P2V {
  position: relative;
}
.ContactUsS1P2VFN {
  position: absolute;
  bottom: -1.5vh;
  right: 0;
  color: var(--gray, #5b7a99);
  font-family: SanDiego;
  font-size: clamp(0.8rem, 0.7vw, 1rem);
  font-size: 0.8vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
}
.ContactUsS1P2VFNMob {
  position: absolute;
  bottom: -1.5vh;
  right: 0.7vw;
  color: var(--gray, #5b7a99);
  font-family: SanDiego;
  font-size: clamp(0.7rem, 0.7vw, 1.3rem);
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
}
.ContactusS4 {
  padding: 7vh 5vh;
  background: #5b7a99;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3vh;
}
.ContactusS4P1 {
  color: #fff;
  font-family: Coco;
  font-size: 2vw;
  font-style: normal;
  font-weight: 500;
  line-height: 126%;
  text-transform: uppercase;
  text-align: center;
  width: 25vw;
}
.ContactusS4P2 {
  color: #fff;
  font-family: SanDiego;
  font-size: 1.6vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
  text-transform: uppercase;
}
.ContactusS4P3B {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.7vw 2vw;
  gap: 4px;
  border-radius: 100px;
  background: var(--blue, #19499b);
  border: none;
  color: #fff;
  font-family: SanDiego, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.2vw;
  line-height: normal;
  letter-spacing: 0.2px;
  cursor: pointer;
  padding-left: 2.5vw;
}
.ContactusS4P3 a,
.ContactusS4P3 a:visited,
.ContactusS4P3 a:active {
  text-decoration: none;
}
.ContactusS4P4,
.ContactusS4P4 a,
.ContactusS4P4 a:active,
.ContactusS4P4 a:visited {
  color: #fff;
  font-family: SanDiego;
  font-size: 1.3vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  text-decoration: none;
}
.ContactusS4P5 {
  color: #fff;
  font-family: SanDiego;
  font-size: 1.3vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
  text-transform: uppercase;
}
@media (max-width: 575px) {
  .ContactusS4P1 {
    font-size: 4.3vw;
    width: 60vw;
  }
  .ContactusS4P2 {
    font-size: 4vw;
    text-align: center;
  }
  .ContactusS4P3B {
    padding: 1.5vw 3.5vw;
    padding-left: 4vw;
    font-size: 4vw;
  }
  .ContactusS4P4,
  .ContactusS4P4 a,
  .ContactusS4P4 a:active,
  .ContactusS4P4 a:visited {
    font-size: 3.5vw;
  }
  .ContactusS4P5 {
    font-size: 3.5vw;
  }
}
