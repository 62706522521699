.HomeS2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 6vw 10vw;
  gap: 3vw;
  align-items: center;
}

.HomeS2P1Img {
  width: 100%;

  border-radius: 8px;
}
.HomeS2P2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2vw;
}
.HomeS2P2V1 {
  color: var(--gray, #5b7a99);
  font-family: SanDiego;
  font-size: 1.3vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
  text-transform: uppercase;
}
.HomeS2P2V2 {
  color: var(--black, #000);
  font-family: Coco;
  font-size: 2.7vw;
  font-style: normal;
  font-weight: 400;
  line-height: 126%;
  letter-spacing: 0.2px;
  text-transform: uppercase;
}
.HomeS2P2V3 {
  color: #657485;
  font-family: SanDiego;
  font-size: 1.3vw;
  font-style: normal;
  font-weight: 400;
  line-height: 136%;
  letter-spacing: 0.2px;
  width: 90%;
  display: flex;
  flex-direction: column;
  gap: 1.5vw;
}
.HomeS2P2V3D2 {
  font-size: 1vw;
}
.HomeS2P2V4 a {
  display: flex;
  align-items: center;
  gap: 0.5vw;
  width: fit-content;
}
.HomeS2P2V4 a,
.HomeS2P2V4 a:active,
.HomeS2P2V4 a:visited {
  color: var(--blue, #19499b);
  text-align: center;
  font-family: SanDiego;
  font-size: 1.5 vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
  text-decoration: none;
}
.HomeS2Mob {
  display: none;
}

@media (max-width: 575px) {
  .HomeS2 {
    display: none;
  }
  .HomeS2Mob {
    display: flex;
    flex-direction: column;
  }
  .HomeS2P2 {
    align-items: center;
    justify-content: center;
  }
  .HomeS2P2V1 {
    font-size: 4vw;
  }
  .HomeS2P2V2 {
    font-size: 5vw;
  }
  .HomeS2P2V3 {
    font-size: 3.5vw;
    width: 80.7vw;
  }
  .HomeS2P1Img {
    width: 80.4vw;
    border-radius: 8px;
  }
  .HomeS2P2V4 {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding-top: 1vh;
    width: 80vw;
  }
  .HomeS2P2V4 a,
  .HomeS2P2V4 a:active,
  .HomeS2P2V4 a:visited {
    color: var(--blue, #19499b);
    text-align: end;
    font-family: SanDiego;
    font-size: 1.5 vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.2px;
    text-decoration: none;
  }
}
/*  */

.HomeS3 {
  background: linear-gradient(180deg, #f6f8fb 0%, #fff 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5vw 0;
  gap: 3vw;
}
.HomeS3P1 {
  display: flex;
  flex-direction: column;
  gap: 3vh;
  text-align: center;
}
.HomeS3P1 h1 {
  color: var(--gray, #5b7a99);
  text-align: center;
  font-family: SanDiego;
  font-size: 1.3vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
  text-transform: uppercase;
}
.HomeS3P1 h2 {
  color: var(--black, #000);
  text-align: center;
  font-family: SanDiego;

  font-size: 2.1vw;
  font-style: normal;
  font-weight: 500;
  line-height: 126%; /* 50.4px */
  letter-spacing: 0.2px;
  text-transform: uppercase;
}
.HomeS3P1 p {
  color: var(--gray, #5b7a99);
  text-align: center;
  font-family: SanDiego;
  font-size: 1.3vw;
  font-style: normal;
  font-weight: 400;
  line-height: 124%;
  letter-spacing: 1px;
}
.HomeS3P2 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 5vw;
}
.HomeS3Get {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 17vw;
}
.HomeS3P2B1 {
  position: relative;
  display: flex;
  flex-direction: column;
}
.HomeS3P2B1::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 68.58%, #19499b 100%);
  z-index: 1;
  pointer-events: none;
  z-index: 2;
}
.HomeS3P2B1Img {
  width: 25vw;
  /* height: 30vw; */
  border-radius: 8px;
  z-index: 1;
}

.HomeS3P2B1H1 {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--white, #fff);
  text-align: center;
  font-family: SanDiego;
  font-size: 2vw;
  font-style: normal;
  font-weight: 500;
  line-height: 126%;
  text-transform: uppercase;
  width: 100%;
  z-index: 2;
}
.HomeS3P2B1 {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
.HomeS3P2B1H2 {
  /* for button style */
  border-radius: 100px;
  background: #ea683b;
  border: none;
  display: flex;
  padding: 0.3vw 1vw;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  /* for text style */
  color: var(--white, #fff);
  font-family: SanDiego;
  font-size: 1vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
  /* for position on image */
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}
.HomeS3P2B1 a,
.HomeS3P2B1 a:visited,
.HomeS3P2B1 a:active {
  text-decoration: none;
  cursor: pointer;
}
.HomeS3P2B1Button {
  display: flex;
  padding: 1vw 0;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 100px;
  background: var(--blue, #19499b);
  border: none;
  /* for text */
  color: #fff;
  font-family: SanDiego;
  font-size: 1vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
  /* for poistion */
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  cursor: pointer;
  z-index: 2;
}
.HomeS3P2B1ButtonTop {
  display: flex;
  padding: 1vw 2vw;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 100px;
  background: var(--blue, #19499b);
  border: none;
  /* for text */
  color: #fff;
  font-family: SanDiego;
  font-size: 1vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
  cursor: pointer;
  /* for poistion */
}
.HomeS3Get a,
.HomeS3Get a:active,
.HomeS3Get a:visited {
  text-decoration: none;
}
.HomeS3RightImg {
  width: 2vw;
}
.HomeS3P2B1ButtonTopMob {
  display: none;
}
@media (max-width: 575px) {
  .HomeS3Get {
    display: none;
  }
  .HomeS3P2B1ButtonTopMob {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 4.5vh;
  }
  .HomeS3P2B1ButtonTopMob a,
  .HomeS3P2B1ButtonTopMob a:active,
  .HomeS3P2B1ButtonTopMob a:visited {
    text-decoration: none;
  }
  .HomeS3P2B1ButtonTop {
    font-size: 4vw;
    padding: 3vw 3vw;
  }
  .HomeS3P1 {
    gap: 1.5vh;
  }
  .HomeS3P1 h1 {
    font-size: 4vw;
  }
  .HomeS3P1 h2 {
    font-size: 4vw;
  }
  .HomeS3P1 p {
    font-size: 4vw;
    width: 80vw;
  }
  .HomeS3P2 {
    display: grid;
    grid-template-columns: 1fr;
    gap: 5vw;
  }
  .HomeS3P2B1Img {
    width: 80.7vw;
    height: 32.3vh;
  }
  .HomeS3P2B1H1 {
    font-size: 4vw;
    top: 10%;
  }
  .HomeS3P2B1H2 {
    font-size: 3vw;
    top: 20%;
  }
  .HomeS3P2B1Button {
    font-size: 4vw;
    padding: 3vw 1vw;
    width: 55%;
    bottom: -5%;
    left: 50%;
    padding-left: 1.5vw;
  }
  .HomeS3RightImg {
    width: 5vw;
    padding-top: 0.7vw;
  }
}

/*  */

/*  */
/*  */
/*  */
.HomeS4 {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 2.5vw;
  margin-top: 5vw;
}
.HomeS4P1 {
  display: flex;
  flex-direction: column;
  gap: 3vh;
  text-align: center;
}
.HomeS4P1 h1 {
  color: var(--gray, #5b7a99);
  text-align: center;
  font-family: SanDiego;
  font-size: 1.5vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
  text-transform: uppercase;
}
.HomeS4P1 h2 {
  color: var(--black, #000);
  text-align: center;
  font-family: Coco;
  font-size: 2.5vw;
  font-style: normal;
  font-weight: 400;
  line-height: 126%; /* 50.4px */
  letter-spacing: 0.2px;
  text-transform: uppercase;
}
.HomeS4P2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5vw;
}
.HomeS4P2Box {
  position: relative;
}
.HomeS4P2Box::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 99%;
  border-radius: 8px;
  background: linear-gradient(600deg, rgba(0, 0, 0, 0) 50%, #19499b 100%);
  pointer-events: none;
  z-index: 2;
}
.HomeS4P2Box img {
  width: 35vw;
  border-radius: 8px;
}
.HomeS4P2Box h3 {
  position: absolute;
  bottom: 5%;
  left: 35%;
  transform: translate(-50%, -50%);
  color: var(--white, #fff);
  font-family: SanDiego;
  font-size: 2vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  width: 100%;
  z-index: 3;
}
.SmallText h3 {
  position: absolute;
  left: 20% !important;
}
.BigText h3 {
  left: 50%;
}
.HomeS4Mob {
  display: none;
}
@media (max-width: 575px) {
  .HomeS4P1 h1 {
    font-size: 4vw;
  }
  .HomeS4P1 h2 {
    font-size: 5vw;
    width: 80vw;
  }
  .HomeS4P2 {
    grid-template-columns: 1fr;
    gap: 2vh;
  }
  .HomeS4P2Box img {
    width: 80vw;
    height: 25vh;
  }
  .HomeS4P2Box h3 {
    bottom: 5%;
    left: 30%;
    font-size: 4vw;
  }
  .SmallText h3 {
    position: absolute;
    left: 18% !important;
  }
  .BigText h3 {
    left: 45%;
  }
  .HomeS4 {
    display: none;
  }
  .HomeS4Mob {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 2.5vw;
    margin-top: 5vw;
  }
}

/*  */
/*  */
/*  */
/*  */
.HomeS5 {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 3vw;
  margin: 5vw 0;
}
.HomeS5P1 h1 {
  color: var(--gray, #5b7a99);
  text-align: center;
  font-family: SanDiego;
  font-size: 1.3vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
  text-transform: uppercase;
}
.HomeS5P3 h1 {
  color: var(--gray, #5b7a99);
  text-align: center;
  font-family: SanDiego;
  font-size: 1.3vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
}
.HomeS5P2 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2vw;
  padding: 0 3vw;
}
.HomeS5P2Box {
  border-radius: 8px;
  background: rgba(236, 240, 249, 0.8);
  padding: 1vw 1vw;
}
.Item1 {
  color: var(--black, #000);
  text-align: center;
  font-family: SanDiego;
  font-size: 2.5vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  text-align: left;
}
.Item2 {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.Item3 {
  color: var(--black, #000);
  font-family: SanDiego;
  font-size: 1.5vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  padding-right: 1vw;
}

.HomeS5Icon3 {
  width: 4vw;
}
.Item2Cont {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding-top: 1vw;
}

@media (max-width: 575px) {
  .HomeS5P1 h1 {
    padding-top: 3vh;
    font-size: 4vw;
  }
  .HomeS5P2 {
    grid-template-columns: 1fr;
    width: 80vw;
    padding-top: 1vh;
  }
  .HomeS5P2Box {
    padding: 3vw 4vw;
  }
  .Item1 {
    font-size: 6vw;
    font-weight: 700;
  }
  .Item3 {
    font-size: 4vw;
  }
  .HomeS5Icon3 {
    width: 12vw;
  }
  .HomeS5P3 h1 {
    font-size: 4vw;
    width: 80vw;
  }
}

/*  */
/*  */
/*  */
.HomeS6 {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f6f8fb;
  padding-top: 5vh;
  padding-bottom: 5vh;
  gap: 7vh;
}
.HomeS6P1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 3vh;
}
.HomeS6P1 h1 {
  color: var(--black, #000);
  text-align: center;
  font-family: Coco;
  font-size: 2.5vw;
  font-style: normal;
  font-weight: 400;
  line-height: 126%; /* 50.4px */
  letter-spacing: 0.2px;
  text-transform: uppercase;
}

@media (max-width: 575px) {
  .HomeS6 {
    margin-top: 5vh;
    gap: 1vh;
  }
  .HomeS6P1 h1 {
    font-size: 5vw;
  }
}

/*  */
/*  */
/*  */
/*  */
.HomeS7 {
  display: grid;
  grid-template-columns: 1fr 1.3fr;
  gap: 3vw;
  padding: 5vw 10vw;
  align-items: center;
}
.HomeS7P1 {
  display: flex;
  flex-direction: column;
  gap: 1.5vw;
}
.HomeS7P1V1 {
  color: var(--black, #000);
  font-family: Coco;
  font-size: 2.3vw;
  font-style: normal;
  font-weight: 400;
  line-height: 126%; /* 50.4px */
  letter-spacing: 0.2px;
  text-transform: uppercase;
}
.HomeS7P1V2 {
  color: var(--gray, #5b7a99);
  font-family: SanDiego;
  font-size: 1.2vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
}
.HomeS7P1V3,
.HomeS7P1V3 a,
.HomeS7P1V3 a:active,
.HomeS7P1V3 a:visited {
  color: var(--blue, #19499b);
  font-family: SanDiego;
  font-size: 1.1vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
  display: flex;
  align-items: center;
  text-decoration: none;
}
.HomeS7P1V3Icon {
  width: 1.5vw;
}
@media (max-width: 575px) {
  .HomeS7 {
    grid-template-columns: 1fr;
    gap: 3vh;
    padding: 10vw 10vw;
    margin-left: 1vw;
  }
  .HomeS7P1V1 {
    font-size: 4.5vw;
    width: 80vw;
    font-weight: 700;
  }
  .HomeS7P1V2 {
    font-size: 3.5vw;
  }
  .HomeS7P1V3 {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding-top: 2vh;
  }
  .HomeS7P1V3 a,
  .HomeS7P1V3 a:active,
  .HomeS7P1V3 a:visited {
    color: var(--blue, #19499b);
    font-family: SanDiego;
    font-size: 4vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.2px;
    display: flex;
    align-items: center;
    text-decoration: none;
  }
  .HomeS7P1V3Icon {
    width: 5vw !important;
  }
}

/*  */
/*  */
/*  */
/*  */
.HomeS8 {
  background: #5b7a99;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5vw 10vw;
  gap: 1.3vw;
}
/* .HomeS8P1 {
  color: var(--white, #fff);
  text-align: center;
  font-family: SanDiego;
  font-size: 1.3vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
  text-transform: uppercase;
} */
.HomeS8P2 {
  color: #fff;
  text-align: center;
  font-family: SanDiego;
  font-size: 2.3vw;
  font-style: normal;
  font-weight: 400;
  line-height: 126%; /* 50.4px */
  letter-spacing: 0.2px;
  text-transform: uppercase;
}
.HomeS8P3 {
  color: var(--white, #fff);
  text-align: center;
  font-family: SanDiego;
  font-size: 1.3vw;
  font-style: normal;
  font-weight: 400;
  line-height: 136%; /* 27.2px */
  letter-spacing: 0.2px;
  width: 59vw;
}
.HomeS8P4 button {
  display: flex;
  align-items: center;
  padding: 1vw 2vw;
  border-radius: 100px;
  background: #19499b;
  border: none;
  color: #f6f8fb;
  font-family: SanDiego;
  font-size: 1vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
  cursor: pointer;
}
.HomeS8P4 a,
.HomeS8P4 a:active,
.HomeS8P4 a:visited {
  text-decoration: none;
}
@media (max-width: 575px) {
  .HomeS8 {
    gap: 1.5vh;
  }
  .HomeS8P2 {
    font-size: 6vw;
    width: 65vw;
  }
  .HomeS8P3 {
    font-size: 4vw;
    width: 80vw;
  }
  .HomeS8P4 button {
    padding: 3vw 3vw;
    font-size: 4.5vw;
    padding-left: 3.5vw;
  }
}
.SpanBigger {
  font-size: 1.5vw;
  font-family: Coco !important;
}
.SpanBiggerMore {
  font-size: 2.5vw;
  font-family: Coco !important;
}
@media (max-width: 575px) {
  .SpanBigger {
    font-size: 4vw;
    font-family: Coco;
  }
  .SpanBiggerMore {
    font-size: 5vw;
    font-family: Coco;
  }
}
.AlCenter {
  text-align: center !important;
  padding-top: 6vh !important;
}
