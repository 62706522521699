.SmallCartMobS1 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/*  */
.SmallCartMobS2 {
  display: flex;
  flex-direction: column;
  /* width: 20vw; */
}
/*  */
.SmallCartMobS2P1 {
  background: #f0f3fa;
  z-index: 1;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 2vw;
  height: 55vh;
}
.SmallCartMobS2P1Head {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2vh;
}
.SmallCartMobS2P1HeadL1 {
  display: flex;
  padding: 2vw 2vh;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 50px;
  border: 1px solid #c9d5e3;
  color: var(--blue, #19499b);
  text-align: center;
  font-family: SanDiego;
  font-size: 3.5vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
  text-transform: uppercase;
}
.SmallCartMobS2P1HeadL2 {
  color: var(--gray, #5b7a99);
  font-family: SanDiego;
  font-size: 4vw;
  font-style: normal;
  font-weight: 400;
  line-height: 124%;
  letter-spacing: 0.5px;
}
.SmallCartMobS2P1HeadL3Border {
  background: #dde6f0;
  height: 0.2vh;
  width: 100%;
}
/*  */
.SmallCartMobS2P1Items {
  display: flex;
  flex-direction: column;
  padding-top: 2vh;
  /* gap: 1.5vh; */
}
.SmallCartMobS2P1ItemsR {
  justify-self: flex-end;
}
.SmallCItemMob {
  display: flex;
  align-items: center;
  gap: 2vw;
  padding-left: 3vw;
}
.SmallCItemMobText {
  color: var(--gray, #5b7a99);
  font-family: SanDiego;
  font-size: 4vw;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.5px;
}
/*  */
.SmallCartMobS2P2Img {
  /* z-index: -1; */
  width: 100%;
  height: 100%;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
/*  */
.SmallCItemMobImg {
  width: 6vw;
}
.ViewAllButtonMob {
  display: flex;
  align-items: center;
  justify-content: center !important;
  background-color: transparent;
  width: 100%;

  border: none;
  border-radius: 5px;
  cursor: pointer;
  color: var(--blue, #19499b);
  font-family: SanDiego;
  font-size: 4vw;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.5px;
  z-index: 3;
}

.CloseButtonMob {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  padding: 0.5em 1em;
  margin-top: 1em;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  color: var(--blue, #19499b);
  font-family: SanDiego;
  font-size: 4vw;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.5px;
}
.SmallCartContainerMob {
  padding: 1vh 0;
}
.ShadowSCMob {
  position: absolute;
  left: 0;
  top: -70%;
  height: 5vh;
  background: linear-gradient(180deg, rgba(240, 243, 250, 0) 0%, #f0f3fa 75.9%);
  width: 70vw;
  z-index: 1;
}
