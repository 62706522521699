.DisplayS3P2B1 {
  position: relative;
  display: flex;
  flex-direction: column;
}
.DisplayS3P2B1Img {
  width: 40vw;
  border-radius: 8px;
}

.DisplayS3P2B1H1 {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--white, #fff);
  text-align: center;
  font-family: SanDiego;
  font-size: 2vw;
  font-style: normal;
  font-weight: 500;
  line-height: 126%;
  text-transform: uppercase;
  width: 100%;
  z-index: 3;
}

.DisplayS3P2B1::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 68.58%, #19499b 100%);
  z-index: 1;
  pointer-events: none;
  z-index: 2;
}
.DisplayS3P2B1 {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
.DisplayS3P2B1H2 {
  /* for button style */
  border-radius: 100px;
  background: #ea683b;
  border: none;
  display: flex;
  padding: 0.3vw 1vw;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  /* for text style */
  color: var(--white, #fff);
  font-family: SanDiego;
  font-size: 1vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
  /* for position on image */
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.DisplayS3P2B1 a,
.DisplayS3P2B1 a:visited,
.DisplayS3P2B1 a:active {
  text-decoration: none;
  cursor: pointer;
}
.DisplayS3P2B1Button {
  display: flex;
  padding: 1vw 1vw;
  padding-left: 2vw;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 100px;
  background: var(--blue, #19499b);
  border: none;
  /* for text */
  color: #fff;
  font-family: SanDiego;
  font-size: 1vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
  /* for poistion */
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}
.DisplayS3RightImg {
  width: 2vw;
}
@media (max-width: 575px) {
  .DisplayS3P2B1Img {
    width: 80vw;
  }
  .DisplayS3P2B1H1 {
    top: 12.5%;
    font-size: 4.5vw;
  }
  .DisplayS3P2B1Button {
    padding: 2vw 2vw;
    font-size: 4vw;
    width: 65%;
    padding-left: 3vw;
    bottom: 0%;
  }
  .DisplayS3RightImg {
    width: 6vw;
  }
}
