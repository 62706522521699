.faq-container {
  width: 100%;
  max-width: 85vw;
  margin-left: auto;
  margin-right: auto;
}

.faq-item {
  margin-bottom: 15px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
}

.faq-question {
  font-weight: bold;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--black, #000);
  font-family: Coco;
  font-size: 1.2vw;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0.2px;
  text-transform: uppercase;
}

.faq-icon {
  width: 20px;
  height: 20px;
  margin-left: 10px;
}

.faq-answer {
  margin-top: 10px;
  font-size: 16px;
  color: var(--gray, #5b7a99);
  font-family: SanDiego;
  font-size: 1vw;
  font-style: normal;
  font-weight: 400;
  line-height: 124%;
  letter-spacing: 0.5px;
}

.faq-answer span {
  font-weight: 700;
}

.faq-answer br {
  margin-bottom: 5px;
}
@media (max-width: 575px) {
  .faq-container {
    padding-top: 3vh;
  }
  .faq-question {
    font-size: 4vw;
  }
  .faq-answer {
    font-size: 3vw;
  }
}
