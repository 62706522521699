.BigCartContainerMob {
  padding: 1vh 0;
}
.BigCartMobS1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2vh;
}
.BigCartMobTitle,
.BigCartMobSubtitle {
  text-align: center;
}
.BigCartMobTitle {
  color: var(--gray, #5b7a99);
  font-family: SanDiego;
  font-size: 3.5vw;
  text-transform: uppercase;
}
.BigCartMobSubtitle {
  color: var(--black, #000);
  font-family: Coco;
  font-size: 4vw;
  text-transform: uppercase;
  padding-bottom: 2vh;
}
.BigCartMobS2 {
  display: flex;
  flex-direction: column;
}
.BigCartMobS2P1 {
  background: #f0f3fa;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 2vw;
  height: 55vh;
}
.BigCartMobS2P1Head {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2vh;
}
.BigCartMobS2P1HeadL1 {
  padding: 2vw 2vh;
  justify-content: center;
  align-items: center;
  border: 1px solid #c9d5e3;
  color: var(--blue, #19499b);
  font-family: SanDiego;
  font-size: 3.5vw;
  text-transform: uppercase;
}
.BigCartMobS2P1HeadL2 {
  color: var(--gray, #5b7a99);
  font-family: SanDiego;
  font-size: 4vw;
}
.BigCartMobS2P1HeadL3Border {
  background: #dde6f0;
  height: 0.2vh;
  width: 100%;
}
.BigCartMobS2P1Items {
  display: flex;
  flex-direction: column;
  padding: 2vh 0;
}
.BigCItemMob {
  display: flex;
  align-items: center;
  gap: 2vw;
  padding-left: 3vw;
}
.BigCItemMobText {
  color: var(--gray, #5b7a99);
  font-family: SanDiego;
  font-size: 4vw;
}
.BigCartMobS2P2Img {
  width: 100%;
  /* height: 30vh; */
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.BigCItemMobImg {
  width: 6vw;
}
.CloseButtonMob {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  margin-top: 1em;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  color: var(--blue, #19499b);
  font-family: SanDiego;
  font-size: 4vw;
}
.ViewAllCont {
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  height: 6vh;
  padding-bottom: 1vh;
}
.ShadowBCMob {
  position: absolute;
  top: -70%;
  height: 5vh;
  background: linear-gradient(180deg, rgba(240, 243, 250, 0) 0%, #f0f3fa 75.9%);
  width: 70vw;
}
