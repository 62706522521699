.HeaderWeb_Absoulte {
  padding: 2vw 5vw;
  padding-left: 8vw;
  position: fixed;
  top: 0;
  width: 100%;
  background-color: white;
  padding-bottom: 0.5vw;
  z-index: 10000000;
  border-bottom: 1px solid #e8e8e8;
}
.HeaderWeb {
  display: flex;
  align-items: center;
  gap: 10vw;
  background-color: transparent;
  z-index: 10;
}
.HeaderWebImg {
  width: 15vw;
}
.HeaderRoute {
  display: flex;
  align-items: center;
  gap: 1.5vw;
}
.HeaderRoute a,
.HeaderRoute a:visited,
.HeaderRoute a:active {
  text-decoration: none;
  padding-bottom: 5px;
  font-size: 1.2vw;
  color: #000 !important;
  font-family: SanDiego, sans-serif;
}
.HeaderButton {
  display: flex;
  align-items: center;
  padding: 0.9vw 1.3vw;
  gap: 0.5vw;
  border-radius: 100px;
  background: var(--blue, #19499b);
  border: none;
  color: #fff;
  font-family: SanDiego;
  font-style: normal;
  font-weight: 400;
  font-size: 1vw;
  line-height: normal;
  letter-spacing: 0.2px;
  cursor: pointer;
}
.HeaderButtonImg {
  width: 1.3vw;
}
.scrolled_link {
  color: #000 !important;
}
.active_link,
.active_link a,
.active_link a:visited,
.active_link a:active {
  color: #6088ff !important;
  padding-bottom: 5px !important;
  border-bottom: 3px solid #6088ff;
}
.WashClubMainCont {
  position: relative;
}
.WashClubMainSvg {
  position: absolute;
  left: 80%;
  top: -20%;
  width: 1.7vw;
}
