.Home {
  height: 100vh;
  overflow: hidden;
  position: relative;
}

.video-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  z-index: -10;
  transition: opacity 1s ease-in-out;
  opacity: 0;
}

.video-background.active {
  opacity: 1;
}

.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) -50%, rgba(0, 0, 0, 0.7) 100%);
  z-index: -9;
  pointer-events: none;
}

.HomeS1V2 {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 55vh;
  left: 7vw;
  gap: 3vh;
}
.HomeS1V2P1 {
  color: var(--white, #fff);
  font-family: Coco;
  font-size: 3.5vw;
  font-style: normal;
  font-weight: 400;
  line-height: 123%; /* 59.04px */
  letter-spacing: 1.2px;
  text-transform: uppercase;
  width: 50%;
}
.HomeS1V2P2 {
  color: var(--white, #fff);
  font-family: SanDiego;
  font-size: 1.5vw;
  font-style: normal;
  font-weight: 400;
  line-height: 136%; /* 27.2px */
  letter-spacing: 0.2px;
  width: 50%;
}
.HomeS1V2P3 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 85vw;
  padding-top: 2vh;
}
.HomeS1V2P3T1 a,
.HomeS1V2P3T1 a:active,
.HomeS1V2P3T1 a:visited {
  display: flex;
  align-items: center;
  gap: 0.5vw;
  color: var(--white, #fff);
  text-align: center;
  font-family: SanDiego;
  font-size: 1vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
  text-decoration: none;
  width: fit-content;
  z-index: 10;
}

.HomeS1V2P3T2 {
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  width: 75%;

  /*  */
  color: var(--white, #fff);
  font-family: SanDiego;
  font-size: 1.2vw;
  font-style: normal;
  font-weight: 400;
  line-height: 124%; /* 24.8px */
  letter-spacing: 1px;
}

.scrolling-content {
  display: inline-flex;
  align-items: center;
  animation: scrollRight 13s linear infinite;
}

@keyframes scrollRight {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}
.scrolling-content span {
  width: 0.5vw;
}

@media (max-width: 575px) {
  .Home {
    height: 40vh;
    width: 100vw;
    position: relative;
  }

  .video-background {
    height: 40vh; /* Adjust the image height for mobile screens */
    object-fit: cover;
  }

  .image-overlay {
    height: 40vh; /* Ensure the overlay matches the image height */
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) -50%, rgba(0, 0, 0, 0.47) 100%);
  }

  .HomeS1V2 {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 27vh;
    left: 7vw;
    gap: 1vh;
  }
  .HomeS1V2P3 {
    padding-top: 0;
  }
  .HomeS1V2P1 {
    font-size: 4vw;
    width: 100%;
    text-transform: capitalize;
  }
  .HomeS1V2P2 {
    display: none;
  }
  .HomeS1V2P3T1 a,
  .HomeS1V2P3T1 a:active,
  .HomeS1V2P3T1 a:visited {
    font-size: 3vw;
  }
  .HomeS1V2P3T2 {
    font-size: 3vw;
    position: absolute;
    top: 110%;
    left: 0%;
    width: 75vw;
    margin-top: 2vh;
    margin-left: 7vw;
  }
  .scrolling-content {
    display: inline-flex;
    align-items: center;
    animation: scrollRight 14s linear infinite;
  }
}
