* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
@font-face {
  font-family: SanDiego;
  src: url(./Assets/fonts/San_Diego/SanDiego.ttf);
}
@font-face {
  font-family: Coco;
  src: url(./Assets/fonts/Coco_Gothic/CocoGothic_trial.ttf);
}
#SelfService,
.specific-class {
  scroll-behavior: smooth;
}
