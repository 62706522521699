.Service_slider_container {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}

/*  */
.Service_slider_image {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Service_slider_image img {
  border-radius: 8px;
}

.Service_S2IconLeft {
  position: absolute;
  top: 50%;
  left: 3%;
  border: none;
  cursor: pointer;
  transform: translateY(-50%);
  display: flex;
  gap: 0.8vw;
}
.Service_S2IconRight {
  position: absolute;
  top: 50%;
  right: 3%;
  border: none;
  cursor: pointer;
  transform: translateY(-50%);
  display: flex;
  gap: 0.8vw;
}
.Service_S2IconLeft,
.Service_S2IconRight {
  width: 3.5vw;
}

.Service_SliderImageS2 {
  width: 80vw;
  border-radius: 8px;
}
.Service_slider_indicators {
  position: absolute;
  bottom: 5%;
}
.Service_slider_indicators {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.Service_slider_indicator {
  margin: 0 5px;
}

.Service_slider_indicator_image {
  width: 4vw;
  height: 4vw;
  object-fit: contain;
}
@media (max-width: 575px) {
  .Service_SliderImageS2 {
    height: auto;
  }
  .Service_slider_indicator_image {
    width: 4.5vw;
    height: 4vw;
    object-fit: contain;
  }
  .Service_S2IconLeft,
  .Service_S2IconRight {
    width: 6vw;
  }
}
