.FooterMobMain {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5vh 0;
  background: #f6f8fb;
  margin-top: -0.1vh;
}
.FooterMobS1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5vh;
  width: 85vw;
  padding-bottom: 3vh;
  border-bottom: 2.5px solid #00000023;
}
.FooterMobS1P1 img {
  width: 50vw;
}
.FooterMobS1P2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2vh;
}
.FooterMobS1P2V1 {
  color: var(--black, #000);
  text-align: center;
  font-family: Coco;
  font-size: 4.5vw;
  font-style: normal;
  font-weight: 400;
  line-height: 126%; /* 50.4px */
  letter-spacing: 0.2px;
  text-transform: uppercase;
}
.Footer_Mob_Input {
  border-radius: 5px;
  border: none;
  background: #eaf0fd;
  width: 80vw;
  height: 4.5vh;
  padding: 2vh 0vh;
  gap: 10px;
  /*  */
  /* color: #5b7a99; */
  font-family: SanDiego;
  font-size: 4vw;
  font-style: normal;
  font-weight: 400;
  line-height: 124%; /* 24.8px */
  letter-spacing: 0.5px;
  text-align: center;
}
.Footer_Mob_Input:focus {
  outline: none;
  box-shadow: none;
}
.HeaderMobButton {
  display: flex;
  align-items: center;
  padding: 3vw 4vw;
  gap: 0.5vw;
  border-radius: 100px;
  background: var(--blue, #19499b);
  border: none;
  color: #fff;
  font-family: SanDiego;
  font-style: normal;
  font-weight: 400;
  font-size: 5vw;
  line-height: normal;
  letter-spacing: 0.2px;
  cursor: pointer;
  padding-left: 8vw;
}
.HeaderMobButtonImg {
  width: 6vw;
  margin-top: 2px;
}
.FooterMobS1P2V3 a,
.FooterMobS1P2V3 a:visited,
.FooterMobS1P2V3 a:active {
  text-decoration: none;
}

/*  */
/*  */
/*  */
.FooterMobS2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5vh;
  width: 85vw;
  padding-bottom: 3vh;
  border-bottom: 2.5px solid #00000023;
  padding: 2vh;
}
.FooterMobS2P1 {
  display: flex;
  flex-direction: column;
  gap: 3vh;
}
.FooterMobS2P1V1,
.FooterMobS2P2V1 {
  display: flex;
  flex-direction: column;
  gap: 2vh;
}
.FooterMobS2P1V1T,
.FooterMobS2P2V1T {
  color: #000;
  font-family: SanDiego;
  font-size: 4vw;
  font-style: normal;
  font-weight: 400;
  line-height: 124%; /* 24.8px */
  letter-spacing: 0.5px;
  text-align: center;
}
.FooterMobS2P1V1L,
.FooterMobS2P1V1L a,
.FooterMobS2P1V1L a:visited,
.FooterMobS2P1V1L a:active {
  color: #5b7a99;
  font-family: SanDiego;
  font-size: 4vw;
  font-style: normal;
  font-weight: 400;
  line-height: 124%; /* 24.8px */
  letter-spacing: 0.5px;
  text-align: center;
}
.FooterMobS2Email {
  line-height: 24px;
}
.FooterMobS2P2V1L,
.FooterMobS2P2V1L a,
.FooterMobS2P2V1L a:visited,
.FooterMobS2P2V1L a:active {
  color: #5b7a99;
  font-family: SanDiego;
  font-size: 4vw;
  font-style: normal;
  font-weight: 400;
  line-height: 124%; /* 24.8px */
  letter-spacing: 0.5px;
  text-align: center;
  text-decoration: none;
}
.FooterMobS2P2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2vh;
}
.FooterMobS2P2V1 {
  color: #000;
  font-family: SanDiego;
  font-size: 4vw;
  font-style: normal;
  font-weight: 400;
  line-height: 124%; /* 24.8px */
  letter-spacing: 0.5px;
  text-align: center;
}
.FooterMobS2P2V2 a,
.FooterMobS2P2V2 a:active,
.FooterMobS2P2V2 a:visited {
  text-decoration: none;
}
.FooterMobS3 {
  padding: 4vh 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2vh;
}
.FooterMobS3P1 {
  color: #5b7a99;
  font-family: SanDiego;
  font-size: 4vw;
  font-style: normal;
  font-weight: 400;
  line-height: 124%; /* 24.8px */
  letter-spacing: 0.5px;
  text-align: center;
}
.FooterMobS3P2 {
  display: flex;
  gap: 2vw;
}
.Footer_Soical_Img_Mob {
  width: 10vw;
}
