.Blog_Box_Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1vw;
}
.BlogBoxP1Img {
  width: 35vw;
  border-radius: 8px;
}

.BlogBoxP2 {
  color: var(--gray, #5b7a99);
  text-align: center;
  font-family: SanDiego;
  font-size: 1.2vw;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: 0.2px;
  text-transform: capitalize;
}
.BlogBoxP3 {
  color: var(--black, #000);
  text-align: center;
  font-family: SanDiego;
  font-size: 2.2vw;
  font-style: normal;
  font-weight: 500;
  line-height: 126%; /* 40.32px */
  text-transform: uppercase;
}
.BlogBoxP4 {
  color: var(--gray, #5b7a99);
  font-family: SanDiego;
  font-size: 1.1vw;
  font-style: normal;
  font-weight: 400;
  line-height: 124%; /* 24.8px */
  letter-spacing: 0.5px;
  width: 35vw;
  text-align: left;
}
.BlogBoxP5,
.BlogBoxP5 a,
.BlogBoxP5 a:active,
.BlogBoxP5 a:visited {
  color: var(--blue, #19499b);
  font-family: SanDiego;
  font-size: 1.2vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
  display: flex;
  text-decoration: none;
  text-align: left;
  padding-top: 1vw;
  /* padding-left: 3px; */
}
.BlogBoxP5Icon {
  width: 1.5vw;
}
@media (max-width: 575px) {
  .Blog_Box_Container {
    gap: 1.5vh;
  }
  .BlogBoxP1Img {
    width: 80vw;
  }
  .BlogBoxP2 {
    font-size: 3vw;
  }
  .BlogBoxP3 {
    font-size: 3.5vw;
    font-weight: 500;
    text-align: left;
    width: 80vw;
  }
  .BlogBoxP3 br {
    display: none;
  }
  .BlogBoxP4 {
    font-size: 3vw;
    width: 80vw;
    text-align: left;
  }
  .BlogBoxP5 {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding-top: 1.5vh;
    padding-bottom: 2vh;
  }
  .BlogBoxP5 a,
  .BlogBoxP5 a:active,
  .BlogBoxP5 a:visited {
    font-size: 3.5vw;
    padding-top: 1vw;
  }
  .BlogBoxP5Icon {
    width: 4vw;
  }
}
