.BranchMainContainer {
  padding: 12vh 0;
  display: flex;
  flex-direction: column;
  /* gap: 5vh; */
}
.BranchMainS1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2vw;
  padding-top: 10vh;
}
.BranchMainS1P1 {
  color: var(--gray, #5b7a99);
  text-align: center;
  font-family: SanDiego;
  font-size: 1.2vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}
.BranchMainS1P1_2 {
  border-radius: 100px;
  background: #ea683b;
  padding: 0.5vw 1vw;
  /* for text */
  color: var(--white, #fff);
  font-family: SanDiego;
  font-size: 1.2vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
}
.BranchMainS1P2 {
  color: var(--black, #000);
  text-align: center;
  font-family: Coco;
  font-size: 2vw;
  font-style: normal;
  font-weight: 400;
  line-height: 126%; /* 50.4px */
  letter-spacing: 0.2px;
  text-transform: uppercase;
}
.BranchMainS1P3Img {
  width: 80vw;
  border-radius: 8px;
}
.BranchMainS2 {
  padding: 10vh 0;
  padding-bottom: 0;
}
.BranchMainS3 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.BranchMainS3P1 {
  /* width: 80vw; */
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 2vw;
  padding: 5vh 15vw;
}
.BranchMainS3P1Mob {
  display: none;
}
.BranchMainS5 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4vw;
}
.BranchMainS5P1 {
  color: var(--black, #000);
  text-align: center;
  font-family: Coco;
  font-size: 2.5vw;
  font-style: normal;
  font-weight: 400;
  line-height: 126%;
  letter-spacing: 0.2px;
  text-transform: uppercase;
}
.BranchMainS5P2 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4vw;
}
.BranchMainS2Mob {
  display: none;
}

.BranchesGetButton {
  display: flex;
  align-items: center;
  padding: 0.9vw 1.3vw;
  gap: 0.5vw;
  border-radius: 100px;
  background: var(--blue, #19499b);
  border: none;
  color: #fff;
  font-family: SanDiego;
  font-style: normal;
  font-weight: 400;
  font-size: 1vw;
  line-height: normal;
  letter-spacing: 0.2px;
  cursor: pointer;
}
.BranchesGetButtonImg {
  width: 1.3vw;
}
.BranchesGetButtonMain a,
.BranchesGetButtonMain a:visited,
.BranchesGetButtonMain a:active {
  text-decoration: none;
}
@media (max-width: 575px) {
  .BranchMainS2 {
    display: none;
  }
  .BranchMainS2Mob {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    gap: 2vw;
    padding: 5vh 10vw;
    padding-bottom: 0;
  }
  .BranchMainS1P1 {
    font-size: 3.5vw;
  }
  .BranchMainS3P1 {
    display: none;
  }
  .BranchMainS3P1Mob {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    gap: 2vw;
    padding: 5vh 10vw;
  }
  .BranchMainS1 {
    padding-top: 3vh;
  }
  .BranchMainS1P1_2 {
    font-size: 4vw;
    padding: 1vw 2vw;
  }
  .BranchMainS1P2 {
    font-size: 4vw;
  }
  .BranchMainS1P3Img {
    width: 80vw;
    border-radius: 8px;
  }
  .BranchMainS2 {
    padding-top: 5vh;
  }
  .BranchesGetButton {
    padding: 2vw 3vw;
    font-size: 4vw;
  }
  .BranchesGetButtonImg {
    width: 6vw;
  }
  .BranchMainS5P1 {
    font-size: 5vw;
  }
  .BranchMainS5P2 {
    flex-direction: column;
  }
}
