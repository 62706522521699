.AboutContainer {
  padding: 12vh 0;
}
.AboutS1 {
  padding: 5vw 10vw;
  display: flex;
  flex-direction: column;
  gap: 3vw;
}
.AboutS1P1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 1.5vw;
}
.AboutS1P1V1 {
  color: var(--gray, #5b7a99);
  font-family: SanDiego;
  font-size: 1vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
  text-transform: uppercase;
}
.AboutS1P1V2 {
  color: var(--systemGrey-900, #212121);
  text-align: center;
  font-family: Coco;
  font-size: 2.5vw;
  font-style: normal;
  font-weight: 500;
  line-height: 123%; /* 59.04px */
  letter-spacing: 1.2px;
  text-transform: uppercase;
}
.AboutS1P1VLS {
  padding-top: 2vh;
  border-bottom: 2px solid #5b7a99;
  height: 1vh;
  width: 69vw;
}
.AboutS1P1VHL {
  color: var(--gray, #5b7a99);
  font-family: SanDiego;
  font-size: 1.3vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  padding-top: 6vh;
}
.AboutS1P1V3 {
  color: var(--gray, #5b7a99);
  font-family: SanDiego;
  font-size: 1.2vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
  width: 70vw;
  text-align: left;
}
.NoneBr {
  display: none;
}
.AboutS1P2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.AboutS1P2Img {
  width: 70vw;
  border-radius: 8px;
}
@media (max-width: 575px) {
  .NoneBr {
    display: block;
  }
  .AboutContainer {
    padding-bottom: 0;
  }
  .AboutS1 {
    padding: 0vw 10vw;
  }
  .AboutS1P1 {
    gap: 1.5vh;
  }
  .AboutS1P1V1 {
    font-size: 3vw;
    order: 1;
  }
  .AboutS1P1V2 {
    font-size: 5vw;
    padding-top: 1vh;
    order: 2;
  }
  .AboutS1P2 {
    order: 3;
  }
  .AboutS1P1VLS {
    order: 4;
    width: 79.5vw;
  }
  .AboutS1P1VHL {
    order: 5;
  }
  .AboutS1P1V3 {
    order: 6;
    font-size: 3vw;
    padding: 4vh 0;
    padding-top: 0;
    width: auto;
  }
  .AboutS1P2Img {
    width: 347px;
    height: 200px;
    border-radius: 8px;
  }
  .AboutS1P1VHL {
    font-size: 4vw;
    padding-top: 3vh;
  }
}

/*  */
/*  */
/*  */
/*  */
/*  */

.AboutS2 {
  padding: 0vw 10vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3vw;
}
.AboutS2P1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 3vw;
}
.AboutS2P1V1 {
  color: var(--gray, #5b7a99);
  text-align: center;
  font-family: SanDiego;
  font-size: 1vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.AboutS2P1V2 {
  color: #000;
  text-align: center;
  font-family: Coco;
  font-size: 2.5vw;
  font-style: normal;
  font-weight: 400;
  line-height: 126%; /* 50.4px */
  letter-spacing: 0.2px;
  text-transform: uppercase;
}

.AboutS2P2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5vw;
}

.AboutS2P2Box {
  display: flex;
  flex-direction: column;
  gap: 1vw;
}

.AboutS2P2BoxP1 {
  display: flex;
  align-items: center;
  gap: 1vw;
  color: var(--black, #000);
  font-family: SanDiego;
  font-size: 1.2vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}
.AboutS2P2BoxP1Img {
  width: 1.3vw;
}
.AboutS2P2BoxP2 {
  color: var(--gray, #5b7a99);
  font-family: SanDiego;
  font-size: 1.1vw;
  font-style: normal;
  font-weight: 400;
  line-height: 124%; /* 24.8px */
  letter-spacing: 0.5px;
  width: 25vw;
}

@media (max-width: 575px) {
  .AboutS2P1 {
    gap: 2.5vw;
  }
  .AboutS2P1V1 {
    font-size: 3vw;
  }
  .AboutS2P1V2 {
    font-size: 4vw;
  }
  .AboutS2P2 {
    grid-template-columns: 1fr;
  }
  .AboutS2P2Box {
    width: 80vw;
    /* align-items: center; */
  }
  .AboutS2P2BoxP1 {
    font-size: 3vw;
    padding-bottom: 1vh;
  }
  .AboutS2P2BoxP2 {
    color: var(--gray, #5b7a99);
    font-family: SanDiego;
    font-size: 3vw;
    font-style: normal;
    font-weight: 400;
    line-height: 124%; /* 24.8px */
    letter-spacing: 0.5px;
    width: 70vw;
    /* text-align: center; */
  }
  .AboutS2P2BoxP1Img {
    width: 3vw;
  }
}

/*  */
/*  */
/*  */
/*  */
/*  */

.AboutS3 {
  padding: 5vw 10vw;
  padding-top: 3vh;
}
.AboutS3Com {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3vw;
}
.AboutS3P1 {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 5vh;
}
.AboutS3P1Img {
  width: 40vw;
  border-radius: 8px;
}

.AboutS3P2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2vw;
}
.AboutS3P2V1 {
  color: var(--gray, #5b7a99);
  font-family: SanDiego;
  font-size: 1.2vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}
.AboutS3P2V2 {
  color: var(--systemGrey-900, #212121);
  font-family: SanDiego;
  font-size: 2vw;
  font-style: normal;
  font-weight: 500;
  line-height: 123%;
  letter-spacing: 0.2px;
  text-transform: uppercase;
}
.AboutS3P2V3 {
  color: var(--gray, #5b7a99);
  font-family: SanDiego;
  font-size: 1.2vw;
  font-style: normal;
  font-weight: 400;
  line-height: 124%;
  letter-spacing: 0.5px;
}
.AboutS3Mob {
  display: none;
}

@media (max-width: 575px) {
  .AboutS3 {
    display: none;
  }
  .AboutS3Mob {
    display: flex;
  }
  .AboutS3P2 {
    align-items: center;
  }
  .AboutS3P1 {
    padding-top: 0;
  }
  .AboutS3P1Img {
    width: 80.7vw;
    margin: 3vh 0;
    margin-top: 1vh;
    border-radius: 8px;
  }
  .AboutS3P2V1 {
    font-size: 3vw;
  }
  .AboutS3P2V2 {
    padding-top: 2vh;
    font-size: 4vw;
  }
  .AboutS3P2V3 {
    font-size: 3vw;
    text-align: center;
  }
}
/*  */
/*  */
/*  */
.AboutS5 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 5vw 10vw;
  background-image: url("../../Assets/svg/About/AboutS5BG.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  gap: 2vw;
}
.AboutS5P1 {
  color: var(--gray, #5b7a99);
  font-family: SanDiego;
  font-size: 1.2vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}
.AboutS5P2 {
  color: var(--systemGrey-900, #212121);
  font-family: Coco;
  font-size: 2vw;
  font-style: normal;
  font-weight: 500;
  line-height: 123%;
  letter-spacing: 0.2px;
  text-transform: uppercase;
}
.AboutS5P3 button {
  display: flex;
  align-items: center;
  padding: 1vw 2vw;
  border-radius: 100px;
  background: #19499b;
  border: none;
  color: #f6f8fb;
  font-family: SanDiego;
  font-size: 1vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
  cursor: pointer;
}
.AboutS5P3 a,
.AboutS5P3 a:active,
.AboutS5P3 a:visited {
  text-decoration: none;
}
@media (max-width: 575px) {
  .AboutS5 {
    background-image: url("../../Assets/svg/About/AboutS5BGMOB.svg");
    background-size: contain;
    gap: 4vw;
    height: 37vh;
  }
  .AboutS5P1 {
    font-size: 3.5vw;
  }
  .AboutS5P2 {
    font-size: 4vw;
  }
  .AboutS5P3 {
    padding-top: 3vh;
  }
  .AboutS5P3 button {
    padding: 2vw 5vw;
    font-size: 4vw;
  }
}
