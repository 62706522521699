.BigCartContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4vh;
}
.BigCartS1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2vh;
}
.BigCartS1P1 {
  color: var(--gray, #5b7a99);
  text-align: center;
  font-family: SanDiego;
  font-size: 1.2vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}
.BigCartS1P2 {
  color: var(--black, #000);
  text-align: center;
  font-family: Coco;
  font-size: 2vw;
  font-style: normal;
  font-weight: 400;
  line-height: 126%; /* 50.4px */
  letter-spacing: 0.2px;
  text-transform: uppercase;
}
/*  */
.BigCartS2 {
  display: flex;
  flex-direction: column;
  width: 50vw;
}
/*  */
.BigCartS2P1 {
  background: #f0f3fa;
  z-index: 1;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 2fr;
  padding: 2vw;
}
.BigCartS2P1Head {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2vh;
}
.BigCartS2P1HeadL1 {
  display: flex;
  padding: 1vw 2vh;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 50px;
  border: 1px solid #c9d5e3;
  /* for text */
  color: var(--blue, #19499b);
  text-align: center;
  font-family: SanDiego;
  font-size: 1vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
  text-transform: uppercase;
}
.BigCartS2P1HeadL2 {
  color: var(--gray, #5b7a99);
  font-family: SanDiego;
  font-size: 1vw;
  font-style: normal;
  font-weight: 400;
  line-height: 124%; /* 24.8px */
  letter-spacing: 0.5px;
}
.BigCartS2P1HeadL3Border {
  background: #dde6f0;
  height: 0.2vh;
  width: 100%;
}
/*  */
.BigCartS2P1Items {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-top: 5vh;
}
.BigCartS2P1ItemsR {
  justify-self: flex-end;
}
.BigCItem {
  display: flex;
  align-items: center;
  gap: 0.5vw;
}
.BigCItemText {
  color: var(--gray, #5b7a99);
  font-family: SanDiego;
  font-size: 1vw;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.5px;
  line-height: 124%; /* 24.8px */
}
.BigCItemImg {
  width: 1.5vw;
}
/*  */
.BigCartS2P2Img {
  margin-top: -1vh;
  z-index: -1;
  width: 50vw;
  /* height: 40vh; */
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
/*  */
.BigCartS2P1ItemsL,
.BigCartS2P1ItemsR {
  display: flex;
  flex-direction: column;
  gap: 0.4vw;
}
@media (max-width: 575px) {
  .BigCartS1P1 {
    font-size: 3.5vw;
  }
  .BigCartS1P2 {
    font-size: 4vw;
  }
}
@media (min-width: 575px) and (max-width: 750px) {
  .BigCartS2P1Items {
    padding-top: 2vh;
  }
  .BigCItemText {
    font-size: 1.4vw;
  }
  .SmallCartS2P1 {
    /* height: 33vw !important; */
  }
}
@media (min-width: 750px) and (max-width: 1000px) {
  .BigCartS2P1Items {
    padding-top: 2vh;
  }
  .BigCItemText {
    font-size: 1.3vw;
  }
  .SmallCartS2P1 {
    /* height: 32vw !important; */
  }
}
@media (min-width: 1000px) and (max-width: 1200px) {
  .BigCartS2P1Items {
    padding-top: 2vh;
  }
  .BigCItemText {
    font-size: 1.2vw;
  }
  .SmallCartS2P1 {
    /* height: 32vw !important; */
  }
}
@media (min-width: 1200px) and (max-width: 1300px) {
  .BigCartS2P1Items {
    padding-top: 2vh;
  }
  .BigCItemText {
    font-size: 1.1vw;
  }
  .SmallCartS2P1 {
    /* height: 32vw !important; */
  }
}
