.Reviews {
  padding: 11.5vw 0;
  padding-bottom: 5vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5vw;
}

.RS1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3.5vw;
}
.RS1P1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.7vw;
}
.RS1P1T1 {
  color: var(--systemGrey-900, #212121);
  text-align: center;
  font-family: Coco;
  font-size: 2.5vw;
  font-style: normal;
  font-weight: 500;
  line-height: 123%; /* 59.04px */
  letter-spacing: 1.2px;
  text-transform: uppercase;
}

.RS1P1T2 {
  color: var(--gray, #5b7a99);
  font-family: SanDiego;
  font-size: 1.42vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
  text-align: center;
}
.RS1P2 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 5vw;
}
.RS1P2B1 {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.RS1P2B1::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 0.5vw;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 68.58%, #19499b 100%);
  z-index: 1;
  pointer-events: none;
  z-index: 2;
}
.RS1P2B1Img {
  width: 25vw;
  border-radius: 0.5vw;
  z-index: 1;
}
.RS1P2B1H1 {
  position: absolute;
  top: 8%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--white, #fff);
  text-align: center;
  font-family: SanDiego;
  font-size: 2vw;
  font-style: normal;
  font-weight: 500;
  line-height: 126%;
  text-transform: uppercase;
  width: 100%;
  z-index: 2;
}
.RS1P2B1H2 {
  border-radius: 100px;
  background: #ea683b;
  border: none;
  display: flex;
  padding: 0.3vw 1vw;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  /* for text style */
  color: var(--white, #fff);
  font-family: SanDiego;
  font-size: 1vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
  /* for position on image */
  position: absolute;
  top: 17%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}
.RS1P2B1V {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 2vw;
  gap: 2vw;
}
.RS1P2B1V1 {
  text-transform: uppercase;
}
.RS1P2B1V2 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.7vw;
}
.RS1P2B1V2 img {
  width: 8vw;
}

@media (max-width: 768px) {
  .Reviews {
    padding: 20vw 0;
    padding-bottom: 5vw;
  }
  .RS1P1T1,
  .RS1P1T2 {
    font-size: 4.5vw;
  }
  .RS1P2 {
    grid-template-columns: 1fr;
  }
  .RS1P2B1Img {
    width: 80vw;
  }
  .RS1P2B1H1 {
    font-size: 4.5vw;
  }
  .RS1P2B1H2 {
    font-size: 3vw;
  }
  .RS1P2B1V {
    padding-top: 5vw;
    gap: 5vw;
  }
  .RS1P2B1V2 {
    gap: 5vw;
  }
  .RS1P2B1V2 img {
    width: 25vw;
  }
}
